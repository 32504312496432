<div #sidebar id="sidebar" class="sidebar left" 
    *ngIf="userService.organization_id != '000' && userService.sidebarOpen && !userService.dashboardLoading">
    <ul class="list-sidebar bg-default">
        <li>
            <a [routerLink]="['/organization', userService.organization_id]"
                [ngClass]="{'active-tab':userService.activeTab=='dashboard'}">
                <i class="mr-2">
                    <!-- <svg-icon url="../../../assets/svgs/dashboard-icon.svg"></svg-icon> -->
                    <svg-icon src="../../../assets/svgs/dashboard-home-icon.svg" type="si-logos-javascript"></svg-icon>
                </i>
                <span class="nav-label">{{this.admin_data.dashboard}}</span></a>
        </li>

        <li
            [ngClass]="{'active-tab' : userService.activeTab=='guides' || userService.activeTab=='manage_keywords' || userService.activeTab=='find_replace' || userService.activeTab=='export_guides' || userService.activeTab=='guide_inventory'}">
            <a *ngIf="['owner',  'partner_admin' , 'mg_admin','app_admin', 'creator'].indexOf(userService.user.role) != -1"
                [routerLink]=" userService.user.role == 'app_admin' ? 
                            ['/organization',
                            userService.organization_id,
                            'guides'] : 
                            ['/organization',
                            userService.organization_id,
                            'guide_explorer']">
                <i class="mr-2">
                    <svg-icon src="../../../assets/svgs/guide-icon.svg" type="si-logos-javascript"></svg-icon>
                </i>
                <span class="nav-label">{{this.admin_data.manageContentVariable12}}</span>
                <div *ngIf="!inSection('manage_guides')" class="ml-auto">
                    <svg-icon src="../../../assets/svgs/caretDownIcon.svg" type="si-logos-javascript"></svg-icon>
                </div>
                <div *ngIf="inSection('manage_guides')" class="ml-auto">
                    <svg-icon src="../../../assets/svgs/caretUpIcon.svg" type="si-logos-javascript"></svg-icon>
                </div>
            </a>
            <ul *ngIf="inSection('manage_guides')">
                <li class="submenu-guides" [ngClass]="{'active-submenu' : userService.activeTab=='guide_inventory'}">
                    <a *ngIf="['owner',  'partner_admin' , 'mg_admin', 'creator'].indexOf(userService.user.role) != -1"
                        [routerLink]="[
                            '/organization',
                            userService.organization_id,
                            'guide_explorer'
                            ]">
                        <i class="mr-2 ml-4">
                            <!-- <svg-icon src="../../../assets/svgs/guide.svg" type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.guideInventory}}</span></a>
                </li>
                <li class="submenu-guides" [ngClass]="{'active-submenu' : userService.activeTab=='guides'}">
                    <a *ngIf="['owner',  'partner_admin' , 'mg_admin','app_admin'].indexOf(userService.user.role) != -1"
                        [routerLink]="[
                            '/organization',
                            userService.organization_id,
                            'guides'
                            ]">
                        <i class="mr-2 ml-4">
                            <!-- <svg-icon src="../../../assets/svgs/transfer-guides.svg" class="Myguide-Logo"
                                type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.transferGuides}}</span></a>
                </li>
                <li class="submenu-guides" [ngClass]="{'active-submenu' : userService.activeTab=='manage_keywords'}">
                    <a *ngIf="['owner',  'partner_admin' , 'mg_admin','app_admin'].indexOf(userService.user.role) != -1" [routerLink]="[
                                                    '/organization',
                                                    userService.organization_id,
                                                    'manage_keywords'
                                                    ]">
                        <i class="mr-2 ml-4">
                            <!-- <svg-icon src="../../../assets/svgs/manage-keywords-icon.svg"
                                                type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.manageKeywords}}</span></a>
                </li>
                <li class="submenu-guides" [ngClass]="{'active-submenu' : userService.activeTab=='find_replace'}">
                    <a *ngIf="['owner',  'partner_admin' , 'mg_admin','app_admin'].indexOf(userService.user.role) != -1"
                        [routerLink]="[
                        '/organization',
                        userService.organization_id,
                        'find_replace'
                    ]">
                        <i class="mr-2 ml-4">

                            <!-- <svg-icon src="../../../assets/svgs/find-and-replace.svg"
                                type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.findReplace}}</span></a>
                </li>
                <li class="submenu-guides" [ngClass]="{'active-submenu' : userService.activeTab=='export_guides'}">
                    <a *ngIf="['owner',  'partner_admin' , 'mg_admin', 'app_admin'].indexOf(userService.user.role) != -1"
                        [routerLink]="[
                                    '/organization',
                                    userService.organization_id,
                                    'export_guides'
                                    ]">
                        <i class="mr-2 ml-4">
                            <!-- <svg-icon src="../../../assets/svgs/export.svg" type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.exportData}}</span></a>
                </li>
            </ul>
        </li>
        <!-- 'superadmin', -->
        <li>
            <a *ngIf="['owner',  'partner_admin' , 'mg_admin'].indexOf(userService.user.role) != -1" [routerLink]="[
                        '/organization',
                        userService.organization_id,
                        'tasks_list'
                      ]" [ngClass]="{'active-tab' : userService.activeTab=='task list'}"><i class="mr-2">

                    <svg-icon src="../../../assets/svgs/task-list-icon.svg" type="si-logos-javascript"></svg-icon>
                </i>
                <span class="nav-label">{{this.admin_data.taskList}}</span></a>
        </li>
        <li [ngClass]="{'active-tab' : userService.activeTab=='segments' || userService.activeTab=='manage_segments','d-none': !userService.enableSegments}">
            <a *ngIf="['owner',  'partner_admin' , 'mg_admin','app_admin'].indexOf(userService.user.role) != -1"
                [routerLink]=" userService.user.role === 'app_admin' ? 
                                ['/organization',
                                    userService.organization_id,
                                    'manage_segments'] :
                                ['/organization',
                                    userService.organization_id,
                                    'segmentation'
                                    ]">
                <i class="mr-2">
                    <svg-icon src="../../../assets/svgs/segment-icon.svg" type="si-logos-javascript"></svg-icon>
                </i>
                <span class="nav-label">{{this.admin_data.segmentation}}</span>
                <div *ngIf="!inSection('segmentation')" class="ml-auto">
                    <svg-icon src="../../../assets/svgs/caretDownIcon.svg" type="si-logos-javascript"></svg-icon>
                </div>
                <div *ngIf="inSection('segmentation')" class="ml-auto">
                    <svg-icon src="../../../assets/svgs/caretUpIcon.svg" type="si-logos-javascript"></svg-icon>
                </div>
            </a>
            <ul *ngIf="inSection('segmentation')">
                <!-- 'superadmin', -->
                <li class="submenu-guides"
                    [ngClass]="{'active-submenu' : userService.activeTab=='segments', 'd-none': !userService.enableSegments}">
                    <a *ngIf="[ 'owner',  'partner_admin' , 'mg_admin'].indexOf(userService.user.role) != -1" [routerLink]="[
                                                  '/organization',
                                                  userService.organization_id,
                                                  'segmentation'
                                                ]"><i class="mr-2 ml-4">
                            <!-- <svg-icon src="../../../assets/svgs/segments-icon.svg"
                                                type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.userAttributes}}</span></a>
                </li>
                <!-- 'superadmin', -->
                <li class="submenu-guides" [ngClass]="{'active-submenu' : userService.activeTab=='manage_segments'}">
                    <a *ngIf="['owner',  'partner_admin' , 'mg_admin','app_admin'].indexOf(userService.user.role) != -1  && serverEnv != 'WestPac'"
                        [routerLink]="['/organization',
                                    userService.organization_id,
                                    'manage_segments'
                                    ]"><i class="mr-2 ml-4">
                
                            <!-- <svg-icon src="../../../assets/svgs/segments-icon.svg"
                                                type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.manageSegments}}</span></a>
                </li>
            </ul>
        </li>
        <!-- 'superadmin', -->
        <li
            [ngClass]="{'active-tab' : userService.activeTab=='customizationCentre' || userService.activeTab=='themes'}">
            <a *ngIf="['owner',  'partner_admin' , 'mg_admin','app_admin'].indexOf(userService.user.role) != -1"
                [routerLink]="
                        ['/organization',
                        userService.organization_id,
                        'customization'
                        ]" (click)="changeSubmenuState()">
                <i class="mr-2">
                    <svg-icon src="../../../assets/svgs/customization-icon.svg" type="si-logos-javascript"></svg-icon>
                </i>
                <span class="nav-label">{{this.admin_data.customization}}</span>
                <div *ngIf="!inSection('customization')" class="ml-auto">
                    <svg-icon src="../../../assets/svgs/caretDownIcon.svg" type="si-logos-javascript"></svg-icon>
                </div>
                <div *ngIf="inSection('customization')" class="ml-auto">
                    <svg-icon src="../../../assets/svgs/caretUpIcon.svg" type="si-logos-javascript"></svg-icon>
                </div>
            </a>
            <!-- 'superadmin',  -->
            <ul *ngIf="inSection('customization')">
                <li class="submenu-guides customization-li" [ngClass]="{'active-submenu':userService.activeTab=='customizationCentre'}"
                    *ngIf="['owner',  'partner_admin' , 'mg_admin', 'app_admin'].indexOf(userService.user.role) != -1">
                    <a [routerLink]="[
                            '/organization',
                            userService.organization_id,
                            'customization'
                          ]" (click)="changeSubmenuState()">
                        <i class="mr-2 ml-2">
                            <!-- <svg-icon src="../../../assets/svgs/customization-center.svg"
                                type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.customizationCentre}}</span>
                        <div *ngIf="!isSubmenu" class="ml-auto">
                            <svg-icon src="../../../assets/svgs/caretDownIcon.svg"
                                type="si-logos-javascript"></svg-icon>
                        </div>
                        <div *ngIf="isSubmenu" class="ml-auto">
                            <svg-icon src="../../../assets/svgs/caretUpIcon.svg"
                                type="si-logos-javascript"></svg-icon>
                        </div>
                    </a>
                    <ul *ngIf="isSubmenu" class="submenu">
                        <li class="submenu-customization"
                            class="{{customizationService.selectedSubmenuSource.value=='branding'?'active-sub-submenu':''}}"
                            (click)="selectSubmenu('branding')">
                            <a><span class="nav-label">{{this.admin_data.branding}}</span></a>
                        </li>
                        <li class="submenu-customization"
                            class="{{customizationService.selectedSubmenuSource.value=='widget'?'active-sub-submenu':''}}"
                            (click)="selectSubmenu('widget')"><a><span class="nav-label">{{this.admin_data.widget}}</span></a>
                        </li>
                        <li class="submenu-customization"
                            class="{{customizationService.selectedSubmenuSource.value=='videogif'?'active-sub-submenu':''}}"
                            (click)="selectSubmenu('videogif')">
                            <a><span class="nav-label">{{this.admin_data.videogif}}</span></a>
                        </li>
                        <li class="submenu-customization"
                            class="{{customizationService.selectedSubmenuSource.value=='step'?'active-sub-submenu':''}}" (click)="selectSubmenu('step')">
                            <a><span class="nav-label">{{this.admin_data.step}}</span></a>
                        </li>
                        <li class="submenu-customization"
                            class="{{customizationService.selectedSubmenuSource.value=='notification'?'active-sub-submenu':''}}"
                            (click)="selectSubmenu('notification')"><a><span
                                    class="nav-label">{{this.admin_data.notification}}</span></a></li>
                        <li class="submenu-customization"
                            class="{{customizationService.selectedSubmenuSource.value=='chatbot'?'active-sub-submenu':''}}"
                            (click)="selectSubmenu('chatbot')">
                            <a><span class="nav-label">{{this.admin_data.chatbot}}</span></a>
                        </li>
                        <li class="submenu-customization"
                            class="{{customizationService.selectedSubmenuSource.value=='features'?'active-sub-submenu':''}}"
                            (click)="selectSubmenu('features')">
                            <a><span class="nav-label">{{this.admin_data.features}}</span></a>
                        </li>
                        <li class="submenu-customization" *ngIf="userService.user.role != 'app_admin'"
                            class="{{customizationService.selectedSubmenuSource.value=='labels'?'active-sub-submenu':''}}"
                            (click)="selectSubmenu('labels')">
                            <a><span class="nav-label">{{this.admin_data.labels}}</span></a>
                        </li>
                    </ul>
                </li>
                <!-- 'superadmin', -->
                <li class="submenu-guides" [ngClass]="{'active-submenu':userService.activeTab=='themes', ' d-none': !userService.enableMediaThemes}"
                    *ngIf="['partner_admin', 'owner',  'mg_admin'].indexOf(userService.user.role) != -1">
                    <a [routerLink]="[
                                '/organization',
                                userService.organization_id,
                                'themes'
                              ]">
                        <i class="mr-2 ml-2">
                            <!-- <svg-icon src="../../../assets/svgs/themes-icon.svg" type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.themes}}</span></a>
                </li>
            </ul>
        </li>

        <li [ngClass]="{'active-tab' : inSection('administration')}">
            <a *ngIf="['superadmin','owner',  'partner_admin' , 'mg_admin','app_admin'].indexOf(userService.user.role) != -1"
                [routerLink]=" userService.user.role == 'app_admin' || userService.user.role == 'owner' ? 
                            ['/organization',
                            userService.organization_id,
                            'application'] : 
                            ['/organization',
                            userService.organization_id,
                            'admin-settings']">
                <i class="mr-2">
                    <svg-icon src="../../../assets/svgs/administration-icon.svg" type="si-logos-javascript"></svg-icon>
                </i>
                <span class="nav-label">{{this.admin_data.administration}}</span>
                <div *ngIf="!inSection('administration')" class="ml-auto">
                    <svg-icon src="../../../assets/svgs/caretDownIcon.svg" type="si-logos-javascript"></svg-icon>
                </div>
                <div *ngIf="inSection('administration')" class="ml-auto">
                    <svg-icon src="../../../assets/svgs/caretUpIcon.svg" type="si-logos-javascript"></svg-icon>
                </div>
            </a>
            <ul *ngIf="inSection('administration')">
                <li class="submenu-guides" [ngClass]="{'active-submenu' : userService.activeTab=='admin settings'}"
                    *ngIf="userService.user.role == 'superadmin' || userService.user.role == 'mg_admin' || userService.user.role == 'partner_admin'">
                    <a [routerLink]="[
                      '/organization',
                      userService.organization_id,
                      'admin-settings'
                    ]">
                        <i class="mr-2 ml-4">
                            <!-- <svg-icon src="../../../assets/svgs/admin-settings-icon.svg"
                                type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.adminSettings}}</span></a>
                </li>
                <!-- 'superadmin', -->
                <li *ngIf="[ 'owner', 'app_admin', 'partner_admin' , 'mg_admin'].indexOf(userService.user.role) != -1"
                    class="submenu-guides" [ngClass]="{'active-submenu' : userService.activeTab=='applications'}">
                    <a [routerLink]="[
                          '/organization',
                          userService.organization_id,
                          'application'
                        ]"><i class="mr-2 ml-4">
                            <!-- <svg-icon src="../../../assets/svgs/application-icon.svg"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.applications}}</span></a>
                </li>
                <!-- </li> -->
                <li *ngIf="['owner',  'mg_admin', 'partner_admin'].indexOf(userService.user.role) != -1"
                    class="submenu-guides" [ngClass]="{'active-submenu' : userService.activeTab=='api & services'}">
                    <a [routerLink]="[
                          '/organization',
                          userService.organization_id,
                          'get_secret_key'
                        ]">
                        <i class="mr-2 ml-4">
                            <!-- <svg-icon src="../../../assets/svgs/api-and-services-icon.svg"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.apiAndServices}}</span>
                    </a>
                </li>
                <!-- 'superadmin', -->
                <li *ngIf="['owner', 'mg_admin'].indexOf(userService.user.role) != -1" class="submenu-guides"
                    [ngClass]="{'active-submenu' : userService.activeTab=='api connectors', ' d-none': !userService.enableConnector}">
                    <a [routerLink]="[
                          '/organization',
                          userService.organization_id,
                          'api_connector'
                        ]">
                        <i class="mr-2 ml-4">
                            <!-- <svg-icon src="../../../assets/svgs/api-connector-icon.svg"
                                type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.apiConnector}}</span></a>
                </li>
                <!-- 'superadmin', -->
                <li *ngIf="[ 'owner',  'partner_admin' , 'mg_admin'].indexOf(userService.user.role) != -1"
                    class="submenu-guides" [ngClass]="{'active-submenu' : userService.activeTab=='manage roles'}">
                    <a [routerLink]="[
                          '/organization',
                          userService.organization_id,
                          'roles'
                        ]"><i class="mr-2 ml-4">
                            <!-- <svg-icon src="../../../assets/svgs/manage-roles-icon.svg"
                                type="si-logos-javascript"></svg-icon> -->
                        </i> <span class="nav-label">{{this.admin_data.manageRoles}}</span></a>
                </li>
                <li *ngIf="userService.user.role == 'superadmin' || userService.user.role == 'mg_admin' || userService.user.role == 'partner_admin'"
                    class="sub-submenu-guides" [ngClass]="{'active-submenu' : userService.activeTab=='SSO', ' d-none': !userService.enableSSO}">
                    <a [routerLink]="[
                                  '/organization',
                                  userService.organization_id,
                                  'sso'
                                ]">
                        <i class="mr-2 ml-4">
                            <!-- <svg-icon src="../../../assets/svgs/sso-icon.svg" type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.sso}}</span></a>
                </li>
                <li
                    class="sub-submenu-guides"
                    *ngIf="['superadmin','owner',  'partner_admin' , 'mg_admin'].indexOf(userService.user.role) != -1">
                    <a [ngClass]="{'active-sub-submenu' : userService.activeTab == 'users'}" [routerLink]="[
                          '/organization',
                          userService.organization_id,
                          'users'
                        ]">
                        <i class="mr-2 ml-4">
                            <!-- <svg-icon src="../../../assets/svgs/users-icon.svg" type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.users}}</span>
                        <div class="ml-auto" *ngIf="['superadmin'].indexOf(userService.user.role) == -1">
                            <div *ngIf="userService.activeTab=='users' || userService.activeTab=='export_users'"
                                class="ml-auto">
                                <svg-icon src="../../../assets/svgs/caretUpIcon.svg"
                                    type="si-logos-javascript"></svg-icon>
                            </div>
                            <div *ngIf="userService.activeTab!=='users' && userService.activeTab!=='export_users'"
                                class="ml-auto">
                                <svg-icon src="../../../assets/svgs/caretDownIcon.svg"
                                    type="si-logos-javascript"></svg-icon>
                            </div>
                        </div>
                    </a>
                    <ul *ngIf="userService.activeTab=='users' || userService.activeTab=='export_users'">
                        <li class="sub-submenu-guides"
                            [ngClass]="{'active-sub-submenu' : userService.activeTab=='export_users'}">
                            <a *ngIf="['owner',  'partner_admin' , 'mg_admin', 'app_admin'].indexOf(userService.user.role) != -1"
                                [routerLink]="[
                                                    '/organization',
                                                    userService.organization_id,
                                                    'export_users'
                                                    ]">
                                <i class="mr-2 ml-5">
                                    <!-- <svg-icon src="../../../assets/svgs/export.svg"
                                        type="si-logos-javascript"></svg-icon> -->
                                </i>
                                <span class="nav-label">{{this.admin_data.exportData}}</span></a>
                        </li>
                    </ul>
                </li>
                <!-- 'superadmin', -->
                <li *ngIf="['owner',  'partner_admin' , 'mg_admin'].indexOf(userService.user.role) != -1"
                    class="submenu-guides" [ngClass]="{'active-submenu' : userService.activeTab=='user-provisioning'}">
                    <a [routerLink]="[
                  '/organization',
                  userService.organization_id,
                  'provision'
                ]">
                        <i class="mr-2 ml-4">
                            <!-- <svg-icon src="../../../assets/svgs/user-provisioning-icon.svg"
                                type="si-logos-javascript"></svg-icon> -->
                        </i>
                        <span class="nav-label">{{this.admin_data.userProvisioning}}</span></a>
                </li>
            </ul>
        </li>
        <!-- <li *ngIf="['superadmin', 'owner'].indexOf(userService.user.role) != -1">
      <a [routerLink]="[
          '/organization',
          userService.organization_id,
          'domain_list'
        ]" [ngClass]="{'active-tab':userService.activeTab=='domains'}"><i class="mr-2">

          <svg-icon src="../../../assets/svgs/domains-icon.svg" type="si-logos-javascript"></svg-icon>

        </i> <span class="nav-label">{{this.admin_data.domains}}</span></a>
    </li> -->
        <!-- <li
            *ngIf="userService.user.role == 'superadmin' || userService.user.role == 'mg_admin' || userService.user.role == 'partner_admin'">
            <a [routerLink]="[
          '/organization',
          userService.organization_id,
          'sso'
        ]" class="d-none" [ngClass]="{ 'd-block': userService.enableSSO ,'active-tab':userService.activeTab=='SSO'}">
                <i class="mr-2">
                    <!-- <svg-icon src="../../../assets/svgs/sso-icon.svg" type="si-logos-javascript"></svg-icon>
                </i>
                <span class="nav-label">{{this.admin_data.sso}}</span></a>
        </li> -->
        <!-- 'superadmin', -->
        <li *ngIf="['owner',  'partner_admin' , 'mg_admin'].indexOf(userService.user.role) != -1">
            <a [routerLink]="[
                  '/organization',
                  userService.organization_id,
                  'activity'
                ]" [ngClass]="{'active-tab':userService.activeTab=='activity'}"><i class="mr-2">
                    <svg-icon src="../../../assets/svgs/activity-sidepanel-icon.svg" type="si-logos-javascript"></svg-icon>
                </i>
                <span class="nav-label">{{this.admin_data.activity}}</span></a>
        </li>
        <li>
            <a *ngIf="['owner',  'partner_admin' , 'mg_admin'].indexOf(userService.user.role) != -1  && userService.enablePlayerPackages"
                [routerLink]="[
          '/organization',
          userService.organization_id,
          'player_packages'
        ]" [ngClass]="{'d-block': userService.enablePlayerPackages ,'active-tab' : userService.activeTab=='player_packages'}"><i
                    class="mr-2">

                    <svg-icon src="../../../assets/svgs/player-package-icon.svg"></svg-icon>
                </i>
                <span class="nav-label">{{this.admin_data.playerPackages}}</span></a>
        </li>
        <!-- <li>
      <a routerLink="/youtube_settings"><i class="mr-2"></i> <span class="nav-label">Youtube Settings</span></a>
    </li> -->
    </ul>
</div>