<div class="header-div">
  <div class="navbar-header">
    <div class="d-flex w-100 paddingLR-15">
      <a [routerLink]="
          userService.organization_id == '000' || userService.organization_id === undefined
            ? ['']
            : ['/organization', userService.organization_id]
        " class="flex-shrink-0" *ngIf="!url_path">
        <!-- <svg-icon src="../../assets/svgs/new-myguide-logo.svg" class="outline-none header-icon border-0"
      ></svg-icon> -->
        <img src="../../assets/images/CSODLogo.png" class="outline-none header-icon border-0" />
      </a>

      <div class="flex-shrink-0" *ngIf="url_path">
        <!-- <svg-icon src="../../assets/svgs/new-myguide-logo.svg" class="outline-none header-icon border-0"
      ></svg-icon> -->
        <img src="../../assets/images/CSODLogo.png" class="outline-none header-icon-maintenance border-0" />
      </div>

      <!-- <button
        class="float-right btn btn-link btn-sm order-1 order-lg-0 button-left col-lg-9 mt-2"
        id="sidebarToggle"
        [ngClass]="{ disabled: userService.organization_id == '000' }"
      >
        <svg-icon src="../../assets/svgs/toggle.svg"></svg-icon>
      </button> -->
    </div>
  </div>
  <!-- <div class="h-1rem"></div> -->
  <nav class="ml-240 navbar navbar-expand-md navbar-dark fixed-top toggle-view-fixedBar expanded-view header">
    <!-- <h4 class="ml-3 mb-0" *ngIf="userService.activeTab!='SSO'">{{userService.activeTab | titlecase }}</h4>
        <h4 class="ml-3 mb-0" *ngIf="userService.activeTab=='SSO'">{{userService.activeTab}}</h4> -->

    <div *ngIf="userService.organization_id !== undefined && this.userService.organization_id != '000' && !url_path"
      class="outline-none btn switchOrg-btn h-96 d-flex">
      <div class="orgImage org-logo-div">
        <img #orgprofile alt="Org" src="{{ userService.organization.image_url }}{{
            organisationService.cdn_signature
          }}" class="outline-none" (error)="
            orgprofile.src = '../../assets/images/organization_42x42.png'
          " class="logoutBox-org-img" id="logoutBox_org_img" />
      </div>
      <span *ngIf="checkOrgId()" class="orgName font-56">{{
        this.userService.organization.name
        }}</span>

      <button
        *ngIf="(this.userService.user.role == 'superadmin' || this.userService.user.role == 'mg_admin' || this.userService.user.role == 'partner_admin')"
        class="switchOrg-icon-btn"
        [disabled]="!(this.userService.user.role == 'superadmin' || this.userService.user.role == 'mg_admin' || this.userService.user.role == 'partner_admin')"
        [routerLink]="['']" (click)="switch()">
        <!-- <svg-icon  src="../assets/svgs/icon-down-arrow.svg"></svg-icon> -->
        <img class="switch-icon" src="../../assets/images/switchOrg.png" />

        <!-- <img *ngIf="this.userService.user.role =='superadmin'" class="pl-3" src="../../assets/images/switchOrg.png">
            <button class="org-switch-btn" *ngIf="this.userService.user.role =='superadmin'" [disabled]="!(userService.user.role=='superadmin')" [routerLink]="['']" (click)="switch()">
                <svg-icon  src="../assets/svgs/icon-down-arrow.svg"></svg-icon> -->
      </button>
    </div>
    <div class="ml-auto d-flex nav-dropdown">
      <!-- <div class="float-left pt-2 pr-3"> -->
      <!-- <i class="fa fa-angle-down userArrow"></i> -->
      <!-- <ul class="navbar-nav">
          <a
            class="nav-link d-flex align-items-center userDropdown-downloads"
            id="userDropdown"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            (click)="scrollBottom()"
          >
            <svg-icon
              class="action_icon"
              *ngIf="!(userService.user.role == 'app_admin')"
              style="padding-bottom: 4px"
              src="../../assets/svgs/download_icon.svg"
            >
            </svg-icon>
            <h6 class="d-inline-block bottom-0 m-0 pl-1 font14 font-56">
              Download
            </h6>
            <svg-icon src="../assets/svgs/icon-down-arrow.svg"></svg-icon>
          </a>
          <li class="nav-item dropdown">
            <div
              class="dropdown-menu dropdown-size dropdown-menu-right"
              aria-labelledby="userDropdown"
            >
              <a
                href="https://chrome.google.com/webstore/detail/enterprise-guideme/lbhpijbhnnpcobofdieiocbphkdjjbef"
                target="_blank" rel="noopener noreferrer"
                class="dropdown-item"
                >Enterprise Creator</a
              >
              <a
                href="https://appexchange.salesforce.com/listingDetail?listingId=a0N3000000DpmUJEAZ"
                target="_blank" rel="noopener noreferrer"
                class="dropdown-item"
                >Salesforce App</a
              >
              <a
                href="https://chrome.google.com/webstore/detail/guideme-in-a-box/likogbpblokadckoahecnikccganfhnm/"
                target="_blank" rel="noopener noreferrer"
                class="dropdown-item"
                >In-the-Box Player</a
              >
              <a
                href="https://www.microsoft.com/en-us/p/enterprise-guideme/9nbbkmqfd6dq"
                target="_blank" rel="noopener noreferrer"
                class="dropdown-item"
                >Player (Edge)</a
              >
              <a
                href="https://cdn.guideme.io/ieplayer/GuideMe_IE_Enterprise(x64).zip"
                target="_blank" rel="noopener noreferrer"
                class="dropdown-item"
                >Enterprise IE Player</a
              >
              <a
                href="https://chrome.google.com/webstore/detail/myguide-player/fgadldooeamdpekomjcdfgofahnplbde"
                target="_blank" rel="noopener noreferrer"
                class="dropdown-item"
                >Chrome Player</a
              >
            </div>
          </li>
        </ul> -->
      <!-- </div> -->
      <div class="profile-container float-left" *ngIf="!url_path">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link align-items-center userDropdown" id="userDropdown" href="#" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="profile-div">
                <div class="profile-img">
                  <ng-container *ngIf="image_id != '0'; else profile_initials">
                    <img #profile *ngIf="userService.user.image_url" src="{{ userService.user.image_url }}{{
                        userService.user.cdn_signature
                      }}" (error)="setDefaultPic()" />

                    <img *ngIf="!userService.user.image_url" src="../../assets/images/user.png" />
                  </ng-container>
                  <ng-template #profile_initials>
                    <div [ngStyle]="{
                        'background-color':
                          items[
                            userService.user.first_name.charAt(0).toLowerCase()
                          ]
                      }" class="initialsDiv">
                      {{ userService.user.first_name.charAt(0) }}
                    </div>
                  </ng-template>
                </div>
                <span class="header-user-avatar-name textCenter justify-content-center">
                  <span>{{userService.user.first_name}} </span>
                  <svg-icon class="dropdown" src="../../assets/svgs/userInfoDropDownIcon.svg"></svg-icon></span>
              </div>


              <!-- {{ userService.user.first_name }} -->

              <!-- <i class="fa fa-angle-down userArrow"></i> -->
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
              <!-- <div class="m-0 userInfo row" (click)="preventClose()">
                <div class="float-left profileImage w-25">
                  <div class="vertical-align padding-20 mr-2">
                    <ng-container *ngIf="image_id != '0'; else profile_initials">
                      <img #profile *ngIf="userService.user.image_url" src="{{ userService.user.image_url }}{{
                          userService.user.cdn_signature
                        }}" (error)="setDefaultPic()" />

                      <img *ngIf="!userService.user.image_url" src="../../assets/images/user.png" />
                    </ng-container>
                    <ng-template #profile_initials>
                      <div [ngStyle]="{
                          'background-color':
                            items[
                              userService.user.first_name
                                .charAt(0)
                                .toLowerCase()
                            ]
                        }" class="initialsDiv font12">
                        {{ userService.user.first_name.charAt(0) }}
                      </div>
                    </ng-template>
                  </div>
                </div>
                <div class="float-left text-white w-75 padding-20 font12">
                  <div>
                    <b class="font12 userName">{{ userService.user.first_name }}
                      {{ userService.user.last_name }}</b>
                  </div>
                  <div class="user-emailid-text">
                    {{ userService.user.user_email }}
                  </div>
                </div>
              </div> -->
              <!-- <div class="userRole-info row font12" (click)="preventClose()"> -->
              <!-- <div class="text-left col-lg-4 text-color-org">
                  {{this.admin_data.headerVariable1}}
                </div>
                <div class="word-wrap text-left col-lg-8">
                  <b>{{ userService.user.organization.name }}</b>
                </div> -->
              <!-- <div class="word-wrap col-lg-4 text-color-org text-left">
                  {{this.admin_data.headerVariable2}}
                </div>
                <div class="text-left col-lg-8">
                  <b>{{ getRole() | titlecase }}</b>
                </div>
              </div> -->
              <div class="dropdown-options font12 logout-div">
                <!-- <a class="dropdown-item mb-0" (click)="redirectToInsights()" target="_blank">
                  {{userService.user.user_email}}
                </a> -->
                <a class="dropdown-item mb-0">
                  {{this.admin_data.role + ":"}} {{ getRole() | titlecase }}
                </a>
                <a class="dropdown-item mb-0" (click)="redirectToInsights()" target="_blank">
                  <!-- <img class="insights-svg" src="../../assets/svgs/Insights.svg" /> -->
                  {{this.admin_data.headerVariable5}}
                </a>
                <a class="dropdown-item mb-0" (click)="preventClose()">
                  <!-- <img class="change-language" src="../../assets/svgs/change-language.svg" /> -->
                  <div class="width100">
                    <select #type class="form-control-sm language-dropdown" [(ngModel)]="selectedLanguage"
                      (ngModelChange)="updateLanguage()" id="language">
                      <option value="english">English (US)</option>
                      <option value="frenchCanadian">French (Canada)</option>
                      <option value="french">French (France)</option>
                      <option value="japanese">Japanese (Japan)</option>
                      <option value="korean">Korean (Korea)</option>
                    </select>

                    <!-- <app-custom-select [dataList]="languageList" (updateDataList)="updateLanguage()"
                      [selected]="selectedLanguage" componentName="language"></app-custom-select> -->
                  </div>
                </a>

                <a class="dropdown-item" [routerLink]="[
                    '/organization',
                    userService.organization_id,
                    'change_password',
                    userService.user.user_id
                  ]">
                  <!-- <i class="fa fa-key" aria-hidden="true"></i> -->
                  <!-- <img class="change-password" src="../../assets/svgs/new-change-password.svg" /> -->
                  {{this.admin_data.headerVariable3}}
                </a>
                <a class="dropdown-item" (click)="redirectToHelpUrl()" *ngIf="showHelpUrl()">
                  <!-- <img class="help_icon-svg" src="../../assets/svgs/help_icon.svg" /> -->
                  {{this.admin_data.headerVariable6}}
                </a>
                <hr class="logout-divider">


                <a class="dropdown-item mb-0" (click)="logout()">
                  <svg-icon class="dropdown" src="../../assets/svgs/logout.svg"></svg-icon>
                  <!-- <img class="logout-svg" src="../../assets/svgs/new-logout.svg" /> -->
                  <span style="margin-top: 2px;">{{this.admin_data.headerVariable4}}</span>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>