import { Component, OnInit } from "@angular/core";
import { DashboardService } from "../common/services/dashboard.service";
import { User, UserService } from "../common/services/user.service";
import { DBUser } from "../common/services/dashboard.service";
import { OrganisationService } from "../common/services/organisation.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ApplicationService } from "../common/services/application.service";
import { filter } from "rxjs/operators";
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "../common/services/toast-modal.service";
import adminLabels from "../constants/ApplicationStrings/trans_utils";

declare const $: any;

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
    org_id;
    selectedOrg;
    fetch_dashboard = false;
    admin_data = adminLabels;
    is_global = false;
    is_local = false;
    generatedDashboard: boolean;

    constructor(
        private dbService: DashboardService,
        public userService: UserService,
        private organisationService: OrganisationService,
        private router: Router,
        private route: ActivatedRoute,
        private application: ApplicationService,
        private titleService: Title,
        private errorResponseService: ErrorResponseService,
        private toastService: ToastModalService
    ) {
        // this.userService.getUserData()
        //console.log(this.userService)
        this.userService.getUserData();
        this.titleService.setTitle("Dashboard" + " | " + "Cornerstone Guide")
        this.organisationService.getCDNData()
        this.userService.activeTab = 'dashboard';
        // if (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin") {
        //     this.userService.organization_id = "000";
        //     // this.userService.organization.name = " ";
        // }
        let role = JSON.parse(localStorage.getItem("user"))["role"];
        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];

            console.log("check")
            // console.log(this.userService);


            if ((!this.org_id) && (this.userService.user.role == "superadmin" || this.userService.user.role == "partner_admin")) {
                this.userService.organization_id = '000'
                localStorage.removeItem("organization")
                this.is_global = true;
            }
            if ((this.org_id) && (this.userService.user.role == "superadmin" || this.userService.user.role == "partner_admin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "owner" || this.userService.user.role == "creator")) {
                this.is_local = true;
                
            }

            if ((this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin")) || (this.org_id == this.userService.organization_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin"))) {
                this.userService.organization_id = this.org_id;
                this.getOrganization();
            }
            else {
                if ((this.userService.user.role === "owner" || this.userService.user.role === "app_admin" || this.userService.user.role === "creator") || (this.userService.user.role == "mg_admin" && this.userService.user['org_ids'].length == 1) ||
                    (this.userService.user.role == "partner_admin" && this.userService.user['org_ids'].length == 1)) {
                    this.userService.organization_id = this.userService.user["org_ids"][0]
                    this.org_id = this.userService.organization_id
                    this.is_local = true;
                    this.getOrganization()
                }

                // if (this.userService.user.role === 'superadmin' && !this.org_id) {
                //     this.getDashboard();
                // }
            }
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs(
                //     "Organization:",
                //     this.route.url["_value"]
                // );
                this.userService.setBreadcrumb([
                    {
                        name: "Dashboard",
                        route: null
                    }
                ])
                this.breadcrumb = this.userService.breadCrumbs;
            });
    }

    getOrganization() {
        if (!this.org_id || this.org_id === '000') { return; };
        this.organisationService.getOrganization(this.org_id).subscribe(
            (response) => {
                let organization = response["data"]["organization"];

                //this.userService.organization = organization["name"];
                if (organization["organization_id"]) {
                    this.userService.organization_id = organization["organization_id"];
                    this.userService.organization = organization;
                    // this.userService.user.cdn_signature = organization["cdn_signature"];
                    let admin_settings = JSON.parse(
                        this.userService.organization.admin_settings
                    );
                    this.userService.enableSegments =
                        admin_settings["guide_segmentation"] == "1" ? true : false;
                    this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
                    this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
                    this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
                    this.userService.enableMediaThemes = admin_settings['media_files_themes'] == 0 ? false : true;
                    this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                    let org = organization;
                    let cdn_sign = {}; //removed unnecessary variable declaration

                    cdn_sign["cdn_signature"] = organization["cdn_signature"];
                    cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                    this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                    this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                    localStorage.setItem("organization", JSON.stringify(org));
                    localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));

                    this.selectedOrg = this.userService.organization_id;
                    this.userService.dashboardLoading = false
                    // this.getDashboard();
                    // if (this.userService.user.role === 'superadmin' && !this.org_id) {
                    //     this.getDashboard();
                    // } else {
                        this.userService.showLoader = false;
                    // }
                }
                else {
                    this.switch()
                }
            },
        );
    }

    dashboard = {
        users: undefined,
        partners: undefined,
        organizations: undefined,
        applications: undefined,
        domains: undefined,
    };
    limit = 10;
    offset = 0;
    users: DBUser[];

    pages = [1, 2, 3];
    currentPage = 1;
    prevIndex = 0;
    currentIndex = 1;
    total = 0;
    breadcrumb;
    pageLimit: number = 0;

    ngOnInit() {
        this.userService.showLoader = true;
        console.log(this.userService.organization_id);
    }

    switch() {
        this.router.navigate([""])
        this.userService.organization_id = "000";
        localStorage.removeItem("organization");
    }

    getDashboard() {

        this.dbService.getDashboard().subscribe(
            (response) => {
                this.userService.showLoader = false;
                this.generatedDashboard = true
                this.dashboard = {
                    users: parseInt(response["data"]["dashboard"]["users"]),
                    partners: parseInt(response["data"]["dashboard"]["partners"]),
                    organizations: parseInt(response["data"]["dashboard"]["organizations"]),
                    applications: parseInt(response["data"]["dashboard"]["applications"]),
                    domains: parseInt(response["data"]["dashboard"]["domains"]),
                };

                this.userService.dashboardLoading = false;
                if (
                    !this.organisationService.cdn_signature &&
                    this.userService.organization_id != "000"
                ) {
                    // this.userService.dashboardLoading = true;
                    this.organisationService
                        .getSignature(this.userService.organization_id)
                        .subscribe(
                            (response) => {
                                let data = response["data"];
                                this.organisationService.cdn_signature = data["cdn_signature"];
                                this.organisationService.cdn_signature_expiry = data['cdn_signature_expiry'];
                                // this.userService.user.cdn_signature = data["cdn_signature"];
                                localStorage.setItem("active_org_id", this.userService.organization_id);

                                let cdn_sign = {};
                                cdn_sign[
                                    "cdn_signature"
                                ] = this.organisationService.cdn_signature;
                                cdn_sign[
                                    "cdn_signature_expiry"
                                ] = this.organisationService.cdn_signature_expiry;

                                localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                                if (this.toastService.showToast) {
                                    this.toastService.showToastMessage(this.toastService.toastType);
                                }
                                this.userService.dashboardLoading = false;
                            },
                            (error) => {
                                this.userService.dashboardLoading = false;
                            }
                        );
                } else {
                    if (this.toastService.showToast) {
                        this.toastService.showToastMessage(this.toastService.toastType);
                    }
                }

            },
        );
    }

    loadDashboard(org) {
        this.org_id = org['organization_id'];
        this.getOrganization();
    }

    trigger() {
        let request = {
            organization_id: this.userService.organization_id
        }

        this.dbService.triggerUpdate(request).subscribe((response) => {
            if (!response['error']) {
                this.toastService.toastType = "trigger_orgjson";
                this.toastService.showToastMessage(this.toastService.toastType);
            }
        })

    }

    clear_session() {
        let request = {
            organization_id: this.userService.organization_id,
        }

        this.dbService.clearSession(request).subscribe((response) => {
            if (!response['error']) {
                this.toastService.toastType = "clear_orgsession";
                this.toastService.showToastMessage(this.toastService.toastType);
            }
        })

    }

    navigateToFeatureFlags() {
        this.router.navigate(['/feature_flags']);
    }

    navigateToPlatformUsage() {
        this.router.navigate(['/platform_usage']);
    }

    generateDashboard(){
        this.getDashboard();
    }

    scrollToOrganization(){
        window.scrollTo(0, 450);
    }

}
