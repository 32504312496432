import adminImportLabelsEnglish from "./en_US";
import adminImportLabelsFrench from "./fr_FR";
import adminImportLabelsFrenchCanadian from "./fr_CA";
import adminImportLabelsJapanese from "./ja_JP";
import adminImportLabelsKorean from "./ko_KR";


let adminLabels = {};
let adminImportLabels = adminImportLabelsEnglish;

if (GmXt === undefined) var GmXt = {};

GmXt.engLbls = {};

GmXt.engLbls = {
    tutorialGuides: "Tutorial Guides",
    userEngagement: "User Engagement",
    guideAutomation: "Guide Automation",
    cioDashboardTitle: "CIO Dashboard",
    guideSurvey: "Guide Survey",
    exitGuideSurvey: "Exit Guide Survey",
    guideStepSurvey: "Guide Step Survey",
    pushNotifications: "Push-notifications",
    tooltipAndValidation: "Tooltip & Validation",
    ductTape: "Duct Tape",
    docTitleGuides: "Guide Insights - Guides",
    docTitleTutorialGuides: "Guide Insights - Tutorial Guides",
    docTitleTooltips: "Guide Insights - Tooltips",
    docTitleUserEngagement: "Guide Insights - User Engagement",
    docTitleSearches: "Guide Insights - Searches",
    docTitleGuideAutomation: "Automation Insights - Guide Automation",
    genericSurvey: "Generic Survey",
    guideWorkflow: " Guide Workflow",
    dynamicWorkflow: " Dynamic Workflow",
    workflows: "Workflows",
    guideInsights: "Guide Insights",
    appInsights: "App Insights",
    automationInsights: "Automation Insights",
    controlCenter: "Control Center",
    myGuideDashboard: "MyGuide Dashboard",
    manageGoals: "Manage Goal",
    manageSurvey: "Manage Survey",
    powerForm: "Power Form",
    digitalDuctTape: "Digital Duct Tape",
    guideMe: "GuideMe",
    showMe: "ShowMe",
    testMe: "TestMe",
    guide: "Guide",
    dashboard: "Dashboard",
    survey: "Survey",
    tooltip: "Tooltip",
    search: "Search",
    workflow: "Workflow",
    goal: "Goal",
    pages: "Pages",
    doItForMe: "DoItForMe",
    beacon: "Beacon",
    Beacons: "Beacons",
    pushNotification: "Push-Notification",
    url: "URL",
    validation: "Validation",
    guideMeDashboard: "Guide Me",
    showMeDashboard: "Show Me",
    testMeDashboard: "Test Me",
    workFlow: "Workflow",
    guidance: "Guidance",
    guidanceAndValidation: "Guidance & Validation",
    pusNotificationAndBeacons: "Push-notifications & Beacons",
    guideMeAndShowMe: "GuideMe & ShowMe",
    tooltips: "tooltips",
    apiAndServices: "APIs & Services",
    apiConnector: "API Connector",
    sso: "SSO",
    // userProvisioning: "User Provisioning",
    api : "API",
    jit : "JIT",
};

GmXt.duplicateLbls = {
    // giphy: "GIF",
    // downloadPptx: "PPT",
    // pdf: "PDF",
    // guidesTab: "Guides",
    // page: "Page",
    // survey: "Survey",
    // publish: "Publish",
    // btnYes: "Yes",
    // btnNo: "No",
    // select: "Select",
    // export: "Export",
    // apply: "Apply",
    // all: "All",
    // slideShow: "Slideshow",
    // video: "Video",
    // seconds: "seconds",
    // refresh: "Refresh",
    // hour: "hour",
    // hours: "hours",
    // inputPlaceHolderSearch: "Search Guide",
}

GmXt.getPlayerLabels = function (lang, number) {

    let selectedLanguage = localStorage.getItem("language");

    switch (selectedLanguage) {
        case 'english':
          adminImportLabels = adminImportLabelsEnglish;
          break;
        case 'french':
          adminImportLabels = adminImportLabelsFrench;
          break;
        case 'frenchCanadian':
          adminImportLabels = adminImportLabelsFrenchCanadian;
          break;
        case 'japanese':
          adminImportLabels = adminImportLabelsJapanese;
          break;
        case 'korean':
          adminImportLabels = adminImportLabelsKorean;
          break;
        default:
          adminImportLabels = adminImportLabelsEnglish;
          break;
      }

    var lab = adminImportLabels;
    if (lang) {
        var l = lang.substr(0, 2);
        if (GmXt.playerLbls[l]) {
            lab = GmXt.playerLbls[l];
        }
    }

    lab = GmXt.mergeLabels(lab, GmXt.engLbls);
    return lab;
};

GmXt.mergeLabels = function (labels, lbs) {
    for (var attr in lbs) {
        labels[attr] = lbs[attr];
    }
    return labels;
};


GmXt.getAllLabels = function (lang) {
    GmXt.label = GmXt.getPlayerLabels(lang,1);
    GmXt.mergeLabels(GmXt.engLbls, GmXt.getPlayerLabels(lang,2));
    const data = GmXt.mergeLabels(GmXt.duplicateLbls, GmXt.getPlayerLabels(lang,3));
    // console.log(data)
    return data
};

adminLabels = GmXt.getAllLabels();

export default adminLabels;

