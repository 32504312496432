<div *ngIf="!loaderFlag" class="container-fluid p-0 login-background  bg-white">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex bg-white loginOverlapDiv p-0 header-div"
            style="height: 72px !important;">
            <div class="w-100 p-3">
                <!-- <svg-icon class="border-0" src="../../assets/svgs/new-myguide-logo.svg"></svg-icon -->
                <img src="../../assets/images/CSODLogo.png" class="outline-none border-0" style="height: 40px;" />
            </div>

        </div>
        <div class="card bg-white card-body w-50 login-div ">
            <div class="p-4 login-form">
            <form [formGroup]="loginForm">
                <span class="login-heading mb-2">{{this.admin_data.myGuideLogin}}</span>
                <span class="font-light-color font14 d-block text-center">{{this.admin_data.readyToStartYourJourney}}</span>
                <span class="font-light-color font14 d-block text-center">{{this.admin_data.commonLoginMsg}}</span>
                <small class="error bg color d-flex align-items-center justify-content-center p-2 mb-2"
                    *ngIf="invalid_credentials">
                    {{this.admin_data.loginInvalidCredentials}}
                </small>

                <small class="error bg color d-flex align-items-center justify-content-center p-2 mb-2"
                    *ngIf="insights_player_login">
                    {{this.admin_data.loginAccessForbidden}}
                </small>

                <div class="form-group mt-4">
                    <label class="fontWeight-700 font14 label-font-color" for="email">{{this.admin_data.email}}</label>
                    <input id="email" class="form-control" type="email" [(ngModel)]="email" formControlName="email"
                        autocomplete="off" placeholder={{this.admin_data.enterEmail}}
                        aria-describedby="emailHelp" required />
                    <div class="font12 height12px">
                        <ng-container *ngIf="loginForm.get('email').touched || loginForm.get('email').dirty">
                            <div class="error color" *ngIf="!loginForm.get('email').valid">
                                {{this.admin_data.loginFieldMandatory}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="form-group">
                    <label class="fontWeight-700 font14 label-font-color" for="pwd">{{this.admin_data.loginPassword}}</label>
                    <input id="pwd" class="form-control" type="password" [(ngModel)]="password" autocomplete="off"
                        formControlName="password" placeholder="{{this.admin_data.enterPassword}}" aria-describedby="passwordHelp" required />
                    <div class="font12 height12px">
                        <ng-container *ngIf="loginForm.get('password').touched || loginForm.get('password').dirty">
                            <div class="error color" *ngIf="!loginForm.get('password').valid">
                                {{this.admin_data.loginFillField}}</div>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="renderCaptcha" class="form-group mt-3">
                    <re-captcha formControlName="recaptcha" (resolved)="captureValue($event)"></re-captcha>
                </div>
                <div class="d-flex">
                    <label class="custom-check">
                        <input type="checkbox" formControlName="remember" />
                        <span class="checkmark mr-3"></span>
                    </label>
                    <span class="mt-1 ml-2">{{this.admin_data.loginRemember}}</span>
                </div>
                <div class="form-group d-block text-center">
                    <button class="btn btn-primary" type="submit"
                        [disabled]="loginForm.invalid" (click)="login(loginForm, 'click')">
                        {{this.admin_data.login}}
                    </button>
                </div>
                <a class="d-block text-center theme-color font13 fontWeight-700" routerLink="/forgotpassword"
                [queryParams]="{redirectURL: this.urlLinkData}">{{this.admin_data.forgotPassword + "?"}}</a>
            </form>
            </div>
        </div>
        <span class="d-block text-center p-3 w-100">
            {{this.admin_data.loginCopyright}}
            {{"Cornerstone " + userService.cuurentYear}}<br>
            {{this.admin_data.loginContactEdcast}}
            <a class="text-bold-800 grey darken-2 supportLink"
                href="mailto:support@csod.com">{{this.admin_data.support}}</a>
        </span>
    </div>

<div *ngIf="loaderFlag" class="container-fluid h-100">
    <div class="app-spinner">
        <img class="spinner-img img-fluid" src="../../../assets/images/g_new_loader.gif" />
    </div>
</div>