import { ChangeDetectorRef, Component, OnInit, AfterViewChecked } from '@angular/core';
import { CustomizationCentre, CustomizationCentreService } from 'src/app/common/services/customization.service';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { UserService } from 'src/app/common/services/user.service';
declare var $: any;
declare var jquery: any;
@Component({
  selector: 'app-modal-rule',
  templateUrl: './modal-rule.component.html',
  styleUrls: ['./modal-rule.component.css']
})
export class ModalRuleComponent implements OnInit {
  appId = '';
  admin_data = adminLabels;
  forProperty: String;
  app: CustomizationCentre = {};

  // rulesType = ["","URL", "URL Hostname", "URL Path", "URL Parameters", "URL Hash", "Page Title"]
  //   rulesCondition = ["","Equals", "Not Equals", "Contains", "Does Not Contain", "Starts With", "Ends With"]
  rulesTypeArray = [
    { value: 'URL', name: this.admin_data.URL},
    { value: 'URL Hostname', name: this.admin_data.urlHostname},
    { value: 'URL Path', name: this.admin_data.urlPath},
    { value: 'URL Parameters', name: this.admin_data.urlParams},
    { value: 'URL Hash', name: this.admin_data.urlHash},
    { value: 'Page Title', name: this.admin_data.pageTitle }
  ]
  rulesConditionArray = [
    { value: 'Equals', name: this.admin_data.equals},
    { value: 'Not Equals', name: this.admin_data.notEquals},
    { value: 'Contains', name: this.admin_data.contains},
    { value: 'Does Not Contain', name: this.admin_data.doesNotContain},
    { value: 'Starts With', name: this.admin_data.startsWith},
    { value: 'Ends With', name: this.admin_data.endsWith }
  ]
  //rulesSections=[];
  parentRulesSections = [];

  // rulesTypeLabel = [this.admin_data.select, this.admin_data.URL, this.admin_data.urlHostname, this.admin_data.urlPath, this.admin_data.urlParams, this.admin_data.urlHash, this.admin_data.pageTitle]
  // rulesConditionLabel = [this.admin_data.select, this.admin_data.equals, this.admin_data.notEquals, this.admin_data.contains, this.admin_data.doesNotContain, this.admin_data.startsWith, this.admin_data.endsWith]
  constructor(private cutomizationService: CustomizationCentreService, public userService: UserService, public cdRef: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  openPopup(app_id) {
    $("#ruleModal").modal("show");
    //console.log(app_id);
    this.appId = app_id;
    this.app = JSON.parse(JSON.stringify(this.cutomizationService.apps[this.cutomizationService.apps.findIndex((app) => {
      return app.appId === app_id
    })]))
    // if(!this.app.settings.rules){
    //     this.app.settings.rules = JSON.parse(JSON.stringify(this.cutomizationService.apps[0].settings.rules))
    // }
    //console.log(this.appId);
    //console.log(this.app.settings.rules); 
    //console.log(this.parentRulesSections);

  }

  closePopup($event) {
    $event.stopPropagation();
    $("#ruleModal").modal("hide");
  }

  changeRuleCondition(value, ruleNo, sectionNo) {
    this.parentRulesSections[sectionNo][ruleNo].condition = value;
  }

  changeRuleType(value, ruleNo, sectionNo) {
    this.parentRulesSections[sectionNo][ruleNo].type = value
  }

  changeRuleValue($event, ruleNo, sectionNo) {
    this.parentRulesSections[sectionNo][ruleNo].value = $event.target.value.trim()
  }

  // isSelectedRuleType(item, rule) {
  //   return this.getRuleType(rule) === item;
  // }

  // isSelectedRuleCondition(item, rule) {
  //   return this.getRuleCondition(rule) === item;
  // }


  deleteRule($event, ruleNo, sectionNo) {
    //console.log(this.parentRulesSections);

    this.parentRulesSections[sectionNo].splice(ruleNo, 1);
    if (this.parentRulesSections[sectionNo].length == 0) { this.parentRulesSections.splice(sectionNo, 1) }
    if (this.parentRulesSections.length == 0) {
      this.addGroup()
    }
  }
  // getRuleType(rule) {
  //   return this.rulesTypeLabel[this.rulesType.findIndex(el => {
  //     return rule.type == el
  //   })]
  // }

  // getRuleCondition(rule) {
  //   return this.rulesCondition[this.rulesCondition.findIndex(el => {
  //     return rule.condition == el
  //   })]
  // }



  addGroup() {
    let default_rule = {
      type: "",
      name: "",
      condition: "",
      value: "",
      logical_condition: "||",
      showValueField: true
    }
    let rules = [default_rule]
    this.parentRulesSections.push(rules)
    //console.log(this.parentRulesSections);

  }

  addRule(sectionNo) {
    this.parentRulesSections[sectionNo].push({
      type: "",
      name: "",
      condition: "",
      value: "",
      logical_condition: "&&",
      showValueField: true
    })
    //console.log(this.parentRulesSections);
  }

  saveRules($event) {
    let localRules = [];
    this.parentRulesSections.forEach(section => {
      section.forEach(rule => {
        //console.log(rule);                
        if (this.validateRule(rule)) {
          localRules.push(JSON.parse(JSON.stringify(rule)))
        }
      });
    })
    //console.log(this.app.settings.rules);
    switch (this.forProperty) {
      case 'widgetIcon': {
        this.app.settings.rules = [];
        this.app.settings.rules = localRules;
        this.addAppToChanged(this.app)
        this.cutomizationService.apps[this.cutomizationService.apps.findIndex(app => { return this.appId == app.appId })].settings.rules = JSON.parse(JSON.stringify(this.app.settings.rules))
        break;
      }
      case 'chatbotIcon': {
        this.app.settings.chatBotrules = [];
        this.app.settings.chatBotrules = localRules;
        this.addAppToChanged(this.app)
        this.cutomizationService.apps[this.cutomizationService.apps.findIndex(app => { return this.appId == app.appId })].settings.chatBotrules = JSON.parse(JSON.stringify(this.app.settings.chatBotrules))

        break;
      }
    }
    this.closePopup($event);

  }

  validateRule(rule) {
    if (!rule || rule.type === "" || rule.condition === "" || rule.value === "") {
      // console.log(rule);
      return false;
    }
    switch (rule.type) {
      case "URL": {
        switch (rule.condition) {
          case "Equals":
          case "Not Equals": {
            return this.isStringValidURLComponent(rule.value);
          }
          case "Contains":
          case "Does Not Contain": {
            return this.isStringValidURLComponent(rule.value)
          }
          case "Starts With": {
            
            return this.isStringValidURLComponent(rule.value)
          }
          case "Ends With": {
            return this.isStringValidURLComponent(rule.value)
          }
        }
      }
      case "URL Hostname": {
        switch (rule.condition) {
          case "Equals":
          case "Not Equals": {
            return this.isStringValidURLHostname(rule.value);
          }
          case "Contains":
          case "Does Not Contain": {
            return this.isStringValidHostnamePart(rule.value)
          }
          case "Starts With": {
            return this.isStringValidURLHostnameStart(rule.value)
          }
          case "Ends With": {
            return this.isValidHostnameEnding(rule.value)
          }
        }
      }

      case "URL Path": {
        switch (rule.condition) {
          case "Equals":
          case "Not Equals": {
            return this.isValidUrlPath(rule.value);
          }
          case "Contains":
          case "Does Not Contain": {
            return this.isUrlPath(rule.value)
          }
          case "Starts With": {
            return this.isUrlPathStart(rule.value)
          }
          case "Ends With": {
            return this.isUrlPathEnd(rule.value)
          }
        }
      }
      case "URL Parameters": {
        switch (rule.condition) {
          case "Equals":
          case "Not Equals": {
            return this.isValidUrlParameters(rule.value);
          }
          case "Contains":
          case "Does Not Contain": {
            return this.isUrlParameter(rule.value)
          }
          case "Starts With": {
            return this.isUrlParameterStart(rule.value)
          }
          case "Ends With": {
            return this.isUrlParameterEnd(rule.value)
          }
        }
      }
      case "URL Hash": {
        switch (rule.condition) {
          case "Equals":
          case "Not Equals": {
            return this.isUrlHash(rule.value);
          }
          case "Contains":
          case "Does Not Contain": {
            return this.isUrlHashPart(rule.value)
          }
          case "Starts With": {
            return this.isUrlHashStart(rule.value)
          }
          case "Ends With": {
            return this.isUrlHashEnd(rule.value)
          }
        }
      }
      case "Page Title": {
        return this.isValidPageTitle(rule.value)
      }
    }
    return false;
  }
  isValidPageTitle(string) {
    // Regular expression to validate page title
    var pageTitleRegex = /^[\w\s\-.,!?:;'"()|]+$/;

    // Test if the provided string matches the regex pattern
    return pageTitleRegex.test(string);
  }

  isUrlHashEnd(string) {
    // Regular expression to validate the end of a URL hash
    var hashEndRegex = /^[a-zA-Z0-9_-]+$/;

    // Test if the provided string matches the regex pattern
    return string !== '' && hashEndRegex.test(string);
  }


  isUrlHashStart(string) {
    // Regular expression to validate the start of a URL hash
    var hashStartRegex = /^#[a-zA-Z0-9_-]*$/;

    // Test if the provided string matches the regex pattern
    return hashStartRegex.test(string);
  }

  isUrlHashPart(string) {
    // Regular expression to validate URL hash part
    var hashPartRegex = /^[a-zA-Z0-9_-]+$/;

    // Test if the provided string matches the regex pattern
    return hashPartRegex.test(string);
  }



  isUrlHash(string) {
    // Regular expression to validate URL hash
    var hashRegex = /^#[a-zA-Z0-9_-]+$/;

    // Test if the provided string matches the regex pattern
    return hashRegex.test(string);
  }


  isUrlParameterEnd(string) {
    // Regular expression to validate the end of a URL parameter
    var parameterEndRegex = /^[a-zA-Z0-9%+-_]*$/;

    // Test if the provided string matches the regex pattern
    return parameterEndRegex.test(string);
  }


  isUrlParameterStart(string) {
    // Regular expression to validate the start of a URL parameter
    var parameterStartRegex = /^[a-zA-Z0-9]+=$/;

    // Test if the provided string matches the regex pattern
    return parameterStartRegex.test(string);
  }

  isUrlParameter(string) {
    // Regular expression to validate URL parameter segment
    var parameterRegex = /^[a-zA-Z0-9%+-_]+=[a-zA-Z0-9%+-_]*$/;

    // Test if the provided string matches the regex pattern
    return parameterRegex.test(string);
  }

  isValidUrlParameters(parameters) {
    // Regular expression to validate URL parameters
    var parametersRegex = /^([a-zA-Z0-9_-]+=[a-zA-Z0-9%+-_]*(&[a-zA-Z0-9_-]+=[a-zA-Z0-9%+-_]*)*)?$/;

    // Test if the provided parameters match the regex pattern
    return parametersRegex.test(parameters);
  }

  isUrlPathEnd(string) {
    // Regular expression to validate the end of a URL path
    var pathEndRegex = /^([^\s/]*\/?)?$/;

    // Test if the provided string matches the regex pattern
    return pathEndRegex.test(string);
  }

  isUrlPathStart(string) {
    // Regular expression to validate the start of a URL path
    var pathStartRegex = /^(\/[^\s/]*)?$/;

    // Test if the provided string matches the regex pattern
    return pathStartRegex.test(string);
  }

  isUrlPath(string) {
    // Regular expression to validate URL path segment
    var pathRegex = /^[a-zA-Z0-9~!*()@\-._]+$/;
    if (this.isValidUrlPath(string)) return true;

    // Test if the provided string matches the regex pattern
    return pathRegex.test(string);
  }

  isValidUrlPath(path) {
    // Regular expression to validate URL path
    var pathRegex = /^\/[^\s/]*$/;

    // Test if the provided path matches the regex pattern
    return pathRegex.test(path);
  }

  isValidHostnameEnding(string) {
    // Check if the string is empty
    if (string.length === 0) {
      return false;
    }

    // Check if the string contains any invalid characters
    var invalidChars = /[^\w-.]/g;
    if (invalidChars.test(string)) {
      return false;
    }

    // Check if the string starts or ends with a hyphen
    if (string.startsWith("-") || string.endsWith("-")) {
      return false;
    }

    // Check if the string is a valid IP address
    var ipRegex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
    if (ipRegex.test(string)) {
      return false;
    }

    // Check if the string is a valid hostname
    var hostnameRegex = /^[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/;
    if (!hostnameRegex.test(string)) {
      return false;
    }

    return true;
  }

  isStringValidURLHostnameStart(str) {
    // Regular expression pattern for a valid URL hostname start
    var hostnameStartPattern = /^[a-zA-Z0-9][a-zA-Z0-9-]*$/;

    // Check if the string matches the pattern
    return hostnameStartPattern.test(str);
  }
  isStringValidHostnamePart(str) {
    // Regular expression pattern for a valid hostname part
    var partPattern = /^[a-zA-Z0-9-_.]+$/;

    // Check if the string matches the pattern
    return partPattern.test(str);
  }

  isStringValidURLHostname(str) {
    // Regular expression pattern for a valid URL hostname
    var hostnamePattern = /^([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

    // Check if the string matches the pattern
    return hostnamePattern.test(str);
  }

  isStringValidURLComponent(str) {// Regular expression pattern for a valid URL component
    var componentPattern = /^[a-zA-Z0-9-_.~!*'(){};:@&=+$,/?#[\]]*$/;

    // Check if the string matches the pattern
    return componentPattern.test(str);
  }

  // isValidURL(url) {
  //     console.log(url);
  //     // Regular expression pattern for a valid URL
  //     var urlPattern = /^(https?:\/\/)?((([a-zA-Z0-9]+|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([a-zA-Z]+|[a-zA-Z][a-zA-Z0-9-]*[a-zA-Z0-9]))(\.[a-zA-Z]{2,}){1,2}(:[0-9]{1,5})?((\/[a-zA-Z0-9]*)*|\.[a-zA-Z]{2,})?(\?[a-zA-Z0-9=&]*)?(#[a-zA-Z0-9]*)?$/;
  //     console.log(urlPattern.test(url));

  //     // Check if the URL matches the pattern
  //     return urlPattern.test(url);
  //   }


  isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }
  isValidURLStartingString(str) {
    // Regular expression pattern for a valid URL starting string
    var startingPattern = /^(https?:\/\/)?[a-zA-Z0-9-_.~!*'();:@&=+$,/?#[\]]*$/;
    // Check if the string matches the pattern
    return startingPattern.test(str);
  }

  isStringValidURLEnding(str) {
    // Regular expression pattern for a valid URL ending
    var endingPattern = /^[a-zA-Z0-9-_.~!*'();:@&=+$,/?#[\]]*$/;

    // Check if the string matches the pattern
    return endingPattern.test(str);
  }

  addAppToChanged(app) {
    if (this.cutomizationService.changedApps.length >= 1) {
      const pos = this.cutomizationService.changedApps.findIndex((app) => {
        return app.appId === this.appId
      })
      if (pos > -1) {
        this.cutomizationService.changedApps.splice(pos, 1)
        this.cutomizationService.changedApps.push(app)

      } else {
        this.cutomizationService.changedApps.push(app)
      }
    }
    else {
      this.cutomizationService.changedApps.push(app)
    }
    //console.log(this.cutomizationService.changedApps);

  }

  validateInput(event: Event) {
    this.userService.validateInput(event);
  }

    validateAllRules() {
        if(this.parentRulesSections.length == 1 && this.parentRulesSections[0].length == 1 && this.isDefaultRule(this.parentRulesSections[0][0])){
            return true;
        }
        for (let i = 0; i < this.parentRulesSections.length; i++) {
            let section = this.parentRulesSections[i]
            for (let j = 0; j < section.length; j++) {
                if (!this.validateRule(section[j])) { return false }
            }
        }
        return true;
    }

    isDefaultRule(rule) {
        if (JSON.stringify(rule) === JSON.stringify({
            "type": "",
            "name": "",
            "condition": "",
            "value": "",
            "logical_condition": "||",
            "showValueField": true
        })) return true;

        return false;
    }

}
