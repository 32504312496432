import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { UserService } from "../common/services/user.service";
import { OrganisationService } from "../common/services/organisation.service";
import { DomainService } from "../common/services/domain.service";
import { DOMAIN_FORMADATA } from "./domain_formdata";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { PaginationService } from '../pagination.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "../common/services/toast-modal.service";
import { SsoListService } from "../common/services/sso-list.service";
import { SSOConfig } from "../common/services/sso.service";
import adminLabels from "../constants/ApplicationStrings/trans_utils";

declare var $: any;

@Component({
    selector: "app-domain",
    templateUrl: "./domain.component.html",
    styleUrls: ["./domain.component.css"],
})
export class DomainComponent implements OnInit {
    org_id;
    limit = 10;
    offset = 0;
    domain_list;
    selected_org;
    onClose = false;

    pages = [1, 2];
    currentPage = 1;
    prevIndex = 0;
    currentIndex = 1;
    total = 0;
    pageLimit: number = 0;
    disableNext = false;
    disablePrev = false;
    showPagination = false;
    search_text = "";
    deleteIndex = 0;
    delete_domain_id;
    onShow = false;
    pageLimitSet = false;
    sso_list;
    breadcrumb;
    admin_data = adminLabels ;
    sortInfo = {};
    domain_formData = JSON.parse(JSON.stringify(DOMAIN_FORMADATA));

    sso_field = {
        type: "select",
        name: "sso",
        value: "",
        disable: false,
        class: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 my-2 font14",
        layoutModal: true,
        options: [],
        validators: [
        ],
        ui: {
            label: this.admin_data.sso,
            class: "font-weight-bold font14",
            inputclass: "form-control",
        },
    }
    formGroup;
    modalHeader: any;
    modalContent: any;



    constructor(
        private route: ActivatedRoute,
        public userService: UserService,
        private organisationService: OrganisationService,
        public domainService: DomainService,
        private router: Router,
        private toastr: ToastrService,
        private titleService: Title,
        public paginationService: PaginationService,
		private errorResponseService : ErrorResponseService,
        private toastService: ToastModalService,
        private ssolistService: SsoListService
    ) {
        this.titleService.setTitle(this.admin_data.domains + " | " + "Cornerstone Guide");
        this.userService.getUserData();
        this.paginationService.initializeValues();
        this.organisationService.getCDNData()
        this.userService.activeTab = 'domains';
        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
            this.userService.organization_id = this.org_id;
            if ((this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin" ))) {
                this.organisationService
                    .getOrganization(this.org_id)
                    .subscribe((response) => {
                        let organization = response["data"]["organization"];

                        this.userService.organization = organization;
                        this.userService.organization_id =
                            organization["organization_id"];
                        
                        // this.userService.user.cdn_signature = organization["cdn_signature"];
                        this.userService.organization = organization;
                        let admin_settings = JSON.parse(
                            this.userService.organization.admin_settings
                        );
                        this.userService.enableSegments =
                            admin_settings["guide_segmentation"] == "1" ? true : false;
                        this.userService.enableSSO =
                            admin_settings["sso"] == 1 ? true : false;
                        this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                        this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;

                        this.userService.enableMediaThemes = admin_settings['media_files_themes'] == 0 ? false : true;

                        let org = organization;
                        let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

                        cdn_sign["cdn_signature"] = organization["cdn_signature"];
                        cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                        this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                        this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                        localStorage.setItem("organization", JSON.stringify(org));
                        localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                        this.selected_org = this.userService.organization_id;
                        // this.ngOnInit();
                    });
            }
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs(
                //     this.admin_data.domains,
                //     this.route.url["_value"]
                // );
                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.domains,
                        route: null
                    }
                ])
                this.breadcrumb = this.userService.breadCrumbs;
            });
    }

    ngOnInit() {
        if (
            this.userService.user.role != "superadmin" ||
            (this.userService.user.role == "superadmin" &&
                this.org_id == this.userService.organization_id)
        ) {
            
            this.getDomainList();
            if (this.userService.enableSSO && this.domain_formData.length == 1 && (this.userService.user.role == 'superadmin' || this.userService.user.role == 'mg_admin' || this.userService.user.role === 'partner_admin' )) {
                this.domain_formData.push(this.sso_field);
            } else {
                this.domain_formData = JSON.parse(JSON.stringify(this.domain_formData));
            }
        }
        let form = {};
        for (let i = 0; i < this.domain_formData.length; i++) {
            form[this.domain_formData[i].name] = new FormControl("");
            let validations = [];
            for (let v of this.domain_formData[i].validators) {
                for (let key in v) {
                    validations.push(key);
                }
            }
            let validators = [];
            for (let j = 0; j < validations.length; j++) {
                switch (validations[j]) {
                    case "required":
                        validators.push(Validators.required);
                        break;

                    case "email":
                        validators.push(Validators.email);
                        break;

                    case "regex":
                        validators.push(
                            Validators.pattern(this.domain_formData[i].validators[j]["regex"])
                        );
                        break;
                }
            }
            form[this.domain_formData[i].name].setValidators(validators);
        }
        this.formGroup = new FormGroup(form);
        this.formGroup
            .get("domain")
            .setValidators([
                Validators.required,
                // Validators.pattern(/^((?!-)[a-zA-Z0-9-]{1,63}(?<!-)[\\.])[a-zA-Z0-9]{2,}$/),
                Validators.pattern(/^((?!-)[A-Za-z0-9-]{1,63}(?!-)[\\.])+[A-Za-z0-9]{2,6}$/)
            ]);
    }

    getDomainList(search = "") {
        // this.domain_list = [];
        this.domainService
            .getDomainList(this.limit, this.offset, (search = ""), this.sortInfo['key'], this.sortInfo['order'])
            .subscribe((response) => {
                this.processDomainResponse(response);
                if (this.userService.enableSSO && (this.userService.user.role == 'superadmin' || this.userService.user.role == 'mg_admin' ||this.userService.user.role === 'partner_admin' )) {
                    this.getSSOList();
                } else {
                    if (this.toastService.showToast) {
                        this.toastService.showToastMessage(this.toastService.toastType);
                    }
                }
            });
    }

    getSSOList() {
        let self = this;
        var promise = new Promise((resolve) => {
            let ssolistService = this.ssolistService;
            let limit = 100;
            let offset = 0;
            let cb = ssolistService.geSSOList(limit, offset);
            let allSso = [];
            let fetchRows = false;
            let rows;
            function application() {
                cb.subscribe(
                    (data) => {

                        Array.prototype.push.apply(
                            allSso,
                            data["data"]["configurations"]
                        );
                        offset += 100;
                        cb = ssolistService.geSSOList(limit, offset);
                        if (!fetchRows) {
                            rows = data["data"]["total_rows"]
                            fetchRows = true;
                        } else {
                            rows = rows % 100;
                        }
                        if (rows / 100 > 1) {
                            application();
                        } else {
                            resolve(allSso);
                        }
                    }
                );
            }
            application();
        }).then(function (data: []) {
            self.sso_list = data;
            //   let options = [{
            //     value: "",
            //     name: "-- Select --"
            //   }];
            let options = [];
            self.sso_list.forEach(sso => {
                options.push({
                    value: sso.id,
                    name: sso.name
                });
            });

            self.domain_formData[1].options = options;
            if (self.toastService.showToast) {
                self.toastService.showToastMessage(self.toastService.toastType);
            }
        });
    }

    processDomainResponse(response) {
        this.domain_list = response["data"]["domains"];
        this.deleteIndex = 0;

        this.paginationService.calculatePageLimit(response['data']['total_rows'], this.limit);
        this.pageLimitSet = true;
        

        this.paginationService.setPaginationStatus(this.domain_list, this.limit);
    }

    appendOffsetAndGetDomainList() {
        let offset = this.paginationService.appendOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getDomainList();
        }
    }

    prependOffsetAndGetDomainList() {

        let offset = this.paginationService.prependOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getDomainList();
        }
    }

    setIndex(ind) {
        let offset = this.paginationService.setIndex(ind, this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getDomainList();
        }
    }

    showAddDomainModal() {
        $("#addDomainModal").modal("show");
        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
        this.resetFormData();
        this.domainService.isEdit = false;
        this.domainService.selectedDomain = null;
    }

    getDomainData(value) {
        this.domainService.isEdit = true;
        this.domainService.selectedDomain = value;
        this.formGroup.patchValue({
            domain: this.domainService.selectedDomain.domain,
            sso: this.domainService.selectedDomain.sso_id ? this.domainService.selectedDomain.sso_id : ''
        })
        $("#addDomainModal").modal("show");
        this.domain_formData[0].input.disabled = true;
    }

    closeAddDomainModal() {
        this.resetFormData();
        $("#addDomainModal").modal("hide");
    }

    resetFormData() {
        for (let i = 0; i < this.domain_formData.length; i++) {
            this.formGroup.patchValue({
                [this.domain_formData[i].name]: "",
            });
        }
        this.domain_formData[0].input.disabled = false;
    }

    showModal(id) {
        // $("#deleteModal").modal("show");
        this.userService.showModal.next(true);
        this.domain_list.forEach((elem, i) => {
            if (elem.domain_id == id) {
                this.deleteIndex = i;
                this.delete_domain_id = elem.domain_id;
            }
        });
        
        this.modalHeader = this.admin_data.deleteDomain;
        let domain = this.domain_list[this.deleteIndex]['domain']
        let str = this.admin_data.deleteDomainContent
        str = str.split(' ')
        str.splice(str.length-1, 0,"'" + domain + "'")
        this.modalContent = str.join(' ');
        this.organisationService.showConfirmationModal.next(true);
    }

    closeModal(value) {
        this.onShow = false;
    }

    deleteDomain(domain) {
        this.userService.delete_button.disable = true;
        this.userService.delete_button.text=this.admin_data.deletingButtonText;
        this.domainService.deleteDomain(this.delete_domain_id).subscribe((response) => {
            this.organisationService.showConfirmationModal.next(false);
            this.userService.delete_button.disable = false;
            this.userService.delete_button.text=this.admin_data.deleteButtonText;
            this.deleteIndex = 0;
            this.pageLimitSet = false;
            this.toastService.showToast = true;
            this.toastService.toastType = "delete_domain";
            if (this.domain_list.length == 1 && this.paginationService.currentPage > 1) {
                this.prependOffsetAndGetDomainList()
            } else {
                this.getDomainList();
            }
        }, error => {
            this.userService.delete_button.disable = false;
            this.userService.delete_button.text=this.admin_data.deleteButtonText;
        });
    }

    addDomain() {
        this.domainService.add_button.disable = true;
        this.domainService.add_button.text = this.admin_data.domainsAddingDomainButtonText;
        let request = {
            domain: this.formGroup.get("domain").value.trim(),
            organization_id: this.org_id,
        };
        this.domainService.addDomain(request).subscribe((response) => {
            if (!response['error']) {
                if (this.userService.enableSSO) {
                    if (this.formGroup.get("sso") && this.formGroup.get("sso").value != '') {
                        let req = {
                            domain_id: response['data']['domain']['domain_id'],
                            sso_id: this.formGroup.get('sso').value
                        }
                        this.domainService.ssomapping(req).subscribe((res) => {
                            // if(this.domain_list.length==this.limit && this.paginationService.currentPage==this.paginationService.pageLimit){
                            //     this.paginationService.calculatePageLimit((this.offset+this.limit)+1);
                            //     this.appendOffsetAndGetDomainList();
                            // }else{
                            // this.getDomainList();
                            // }
                            this.getDomainList();
                        })
                    } else {
                        this.getDomainList();
                    }
                } else {
                    this.getDomainList();
                }
                this.domainService.add_button.disable = false;
                this.domainService.add_button.text = this.admin_data.domainsAddDomainButtonText;
                this.closeAddDomainModal();
                this.pageLimitSet = false;
                this.toastService.showToast = true;
                this.toastService.toastType = "add_domain";
                // if(this.domain_list.length==this.limit && this.paginationService.currentPage==this.paginationService.pageLimit){
                //     this.paginationService.calculatePageLimit((this.offset+this.limit)+1);
                //     this.appendOffsetAndGetDomainList();
                // }else{
                // this.getDomainList();
                // }
            } else {
                this.domainService.add_button.disable = false;
                this.domainService.add_button.text = this.admin_data.domainsAddDomainButtonText;
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage=errorResponse;
                this.closeAddDomainModal();
                $("#errorModal").modal("show");
                this.userService.hide();
            }
        }, error => {
            this.domainService.add_button.disable = false;
            this.domainService.add_button.text = this.admin_data.domainsAddDomainButtonText;
        });
    }

    updateDomain() {
        if (this.userService.enableSSO) {
            let req
            if (this.formGroup.get("sso") && this.formGroup.get("sso").value != '') {
                req = {
                    domain_id: this.domainService.selectedDomain['domain_id'],
                    sso_id: this.formGroup.get('sso').value
                }
            } else {
                req = {
                    domain_id: this.domainService.selectedDomain['domain_id']
                }
            }
            this.domainService.ssomapping(req).subscribe((res) => {
                this.toastService.showToast = true;
                this.toastService.toastType = "update_sso_domain_mapping";
                this.getDomainList();
            })
        }
        this.closeAddDomainModal();
    }

    gotoFirstPage() {
        this.setIndex(1);
    }

    gotoLastPage(event) {
        this.setIndex(event);
    }

    changeLimit(event) {
        this.limit = parseInt(event);
        this.offset = 0;
        this.getDomainList();
        this.paginationService.initializeValues();
    }

    gotoPage(page) {
        this.offset = this.paginationService.gotoPage(page, this.limit, this.offset)
        if (this.offset >= 0) this.getDomainList();
    }

    sortData(columnName){
        
        let offset = this.paginationService.setIndex(1, this.limit, this.offset);
        this.offset = 0;
        if(this.sortInfo){
            if(this.sortInfo['key'] === columnName){
                if(this.sortInfo['order'] === 'asc'){
                    this.sortInfo['order'] = 'desc'
                } else{
                    this.sortInfo['order'] = 'asc'
                }
            } else {
                this.sortInfo['key'] = columnName;
                this.sortInfo['order'] = 'asc'
            }            
            this.getDomainList()
        }
    }

}