<div class="scroll-x table-container" [ngClass]="getWidth()">
  <table *ngIf="tableData" [id]="anotherOne != '' ? anotherOne : 'dataTable'"
    class="table table-bordered 12 tl-fixed break-w" [ngClass]="minWidth != '' ? 'min-w-100' : ''" datatable="ng">
    <thead>
      <tr>
        <ng-container *ngIf="titles">
          <th [class.truncate]="['Description'].indexOf(title)!=-1" *ngFor="let title of titles;index as i">
            <ng-container *ngIf="!(title == 'Mark All') && !(sortable && sortable.includes(dataKeys[i]))">
              <span class="thead-title-wrap">{{ title }}</span>
            </ng-container>
            <ng-container *ngIf="sortable && sortable.includes(dataKeys[i])">
                <span class="d-flex justify-content-between align-items-center">
                    <span class="thead-title-wrap">{{ title }}</span>
                    <svg-icon *ngIf="sortInfo.key !==  dataKeys[i]" src="../../../assets/svgs/sort-neutral-icon.svg" role="button" (click)="emitSortColumn(dataKeys[i])"></svg-icon>
                    <svg-icon *ngIf="sortInfo.key === dataKeys[i] && sortInfo.order === 'asc'" src="../../../assets/svgs/sort-asc-icon.svg" role="button" (click)="emitSortColumn(dataKeys[i])"></svg-icon>
                    <svg-icon *ngIf="sortInfo.key === dataKeys[i] && sortInfo.order === 'desc'" src="../../../assets/svgs/sort-desc-icon.svg" role="button" (click)="emitSortColumn(dataKeys[i])"></svg-icon>
                </span>
            </ng-container>
            <ng-container *ngIf="title == 'Mark All'">
              <label class="custom-check">
                <input type="checkbox" (click)="markAll($event.target.checked)" [checked]="checkSelectAllBox()" />
                &nbsp; &nbsp; {{this.admin_data.selectAll}}
                <span class="checkmark"></span>
              </label>
            </ng-container>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody *ngIf="tableData.length > 0">
      <tr *ngFor="let d of tableData; let i = index">
        <td class="w-12" *ngIf="dataKeys[0] == 'image_url' && d[dataKeys[0]] != ''">
          <span class="tableImage-span">
            <img class="table-avtar" *ngIf="
                d[dataKeys[0]].includes('guideme-defaults/default_icon.png')
              " src="{{ d[dataKeys[0]] }}" />
            <img class="table-avtar" *ngIf="
                !d[dataKeys[0]].includes('guideme-defaults/default_icon.png')
              " src="{{ d[dataKeys[0]] }}{{ organisationService.cdn_signature }}" (error)="d[dataKeys[0]] = ''" />
          </span>
        </td>
        <td class="w-12" *ngIf="dataKeys[0] == 'image_url' && d[dataKeys[0]] == ''">
          <span *ngIf="
              dataKeys[dataKeys.length - 1] != 'app_action';
              else show_initials
            " class="tableImage-span">
            <img class="table-avtar" src="../../assets/images/user.png" />
          </span>
          <ng-template #show_initials>
            <div [ngStyle]="{
                'background-color': items[d['title'].charAt(0).toLowerCase()]
              }" class="initialsDiv">
              {{ d["title"].charAt(0) }}
            </div>
          </ng-template>
        </td>
        <td *ngIf="dataKeys[0] == 'check'">
          <label class="custom-check">
            <input
              type="checkbox"
              (click)="mark($event.target.checked, i)"
              [checked]="checks[i]"
            />
            <span class="checkmark"></span>
          </label>
        </td>
        <ng-container *ngFor="let key of dataKeys.slice(1, 4); let k = index">
          <td *ngIf="
              !(
                key == 'creation_date' ||
                key == 'modification_date' ||
                key == 'sno' ||
                key == 'segment_list_action' ||
                key == 'user_action' ||
                key == 'export_guide_action' ||
                key == 'is_suspend' ||
                key == 'is_locked' ||
                key == 'isAutomationEnabled' ||
                key == 'secret_action' ||
                key == 'progress' ||
                key == 'api_key' ||
                key == 'api_secret' ||
                key == 'is_sync_complete' ||
                key == 'completion_count' ||
                key == 'email' ||
                key == 'full_name' ||
                key == 'is_required' ||
                key == 'user_email' ||
                key == 'default_value' ||
                key == 'key_name' ||
                key == 'value' ||
                key == 'description' ||
                key == 'activity' ||
                key == 'domain' ||
                (titles[0] == 'SSO Name' && key=='name') ||
                key == 'sso_name'
              )
            ">
            {{ d[key] | titlecase }}
          </td>
          <td *ngIf="(
            key == 'key_name' ||
            key == 'value' ||
            key == 'description' ||
            key == 'activity' ||
            key == 'domain' ||
            (titles[0] == 'SSO Name' && key=='name')
          )">
            {{d[key]}}
          </td>
          <td *ngIf="key=='sso_name'">
            <span class="color-red" *ngIf="d[key]==null;else showName">{{this.admin_data.tableUnmapped}}</span>
            <ng-template #showName>
              {{d[key]}}
            </ng-template>
          </td>
          <td *ngIf="key == 'sno'">{{ i + 1 }}</td>
          <td *ngIf="key == 'api_key' || key == 'api_secret'" class="positionRelative">
            <div class="copiedText-div" id="copiedText_{{ i }}_{{ k }}">
              {{this.admin_data.copied}}
            </div>
            <div class="ngxclipboard d-inline-flex">
              <input class="form-control code-value" #clip value="{{ d[key] }}" disabled />
              <!-- <button class="code-copy-btn" (click)="showCopiedDiv(i, k)" type="button" [cbContent]="clip.value"
              (cbOnSuccess)="copySuccess(i, 'api_key')">{{this.admin_data.copy}}</button> -->
              <button class="code-copy-btn" (click)="showCopiedDiv(i, k)" type="button" ngxClipboard
                [cbContent]="clip.value" (cbOnSuccess)="copySuccess(i, 'api_key')">
                {{this.admin_data.copy}}
              </button>
            </div>
          </td>
          <td *ngIf="key == 'creation_date' || key == 'modification_date'">
            {{ d[key] * 1000 | date: "EEE, MMM d, y" }}
          </td>
          <td *ngIf="key == 'progress'">
            <div class="progress">
              <div class="progress-bar text-overflow" role="progressbar" [attr.aria-valuenow]="d[key]"
                [style.width]="d[key]+'%'" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            {{ d[key] }} %
          </td>
          <td *ngIf="key == 'email' || key == 'user_email'">{{ d[key] }}</td>
          <td *ngIf="key == 'full_name'">{{ d[key] | titlecase }}</td>
          <td *ngIf="key == 'is_sync_complete' || key == 'is_required'"
            [style.color]="d[key] == '1' ? '#005BF0' : '#F1736A'">
            <ng-container *ngIf="d[key] == '1'">{{this.admin_data.yes}}</ng-container>
            <ng-container *ngIf="d[key] == '0'">{{this.admin_data.no}}</ng-container>
          </td>
          <td *ngIf="key == 'default_value' && userService.include_default_segment"
            [style.color]="d[key] == d['value'] ? '#005BF0' : '#F1736A'">
            <ng-container *ngIf="d[key] == d['value']">{{this.admin_data.yes}}</ng-container>
            <ng-container *ngIf="!(d[key] == d['value'])">{{this.admin_data.no}}</ng-container>
          </td>
          <td *ngIf="
              key == 'app_action' ||
              key == 'role_action' ||
              key == 'segment_action' ||
              key == 'segment_list_action'
            ">
            <div (click)="emitDelete(d)">
              <svg-icon class="action_icon" *ngIf="!(userService.user.role == 'app_admin')" data-toggle="tooltip"
                data-placement="bottom" title="{{this.admin_data.deleteButtonText}}"
                src="../../../assets/svgs/delete.svg">
              </svg-icon>
            </div>
          </td>

          <td *ngIf="key == 'completion_count'">
            <div (click)="emitShowLeaderBoard(d)" class="leaderBoard-div">
              <span>
                <svg-icon class="action_icon" *ngIf="!(userService.user.role == 'app_admin')"
                  src="../../../assets/svgs/leaderboard.svg">
                </svg-icon>
              </span>
              <span class="pl-2 pr-2 badge badge-primary custom-badge">{{
                d[key]
                }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container *ngFor="let key of dataKeys.slice(4, dataKeys.length)">
          <td *ngIf="key == 'is_suspend' && d[key] == 0"
            [ngStyle]="{'cursor': (userService.user.role == 'owner' && d['role'] == 'mg_admin') ? 'not-allowed': 'pointer'}">
            <span class="badge user-active-status" (click)="changeStatus(d)"
              [ngStyle]="{'pointer-events': (userService.user.role == 'owner' && d['role'] == 'mg_admin') ? 'none': 'visible'}">{{this.admin_data.organisationActiveText}}</span>
          </td>
          <td *ngIf="key == 'isAutomationEnabled' && (d[key]?true:false)"
            [ngStyle]="{'cursor': (userService.user.role == 'owner' && d['role'] == 'mg_admin') ? 'not-allowed': 'pointer'}">
            <span class="badge user-active-status" (click)="emitAutomationStatus(d)" (click)="changeStatus(d)"
              [ngStyle]="{'pointer-events': (userService.user.role == 'owner' && d['role'] == 'mg_admin') ? 'none': 'visible'}">{{this.admin_data.on}}</span>
          </td>

          <td *ngIf="key == 'isAutomationEnabled' && (!d[key]?true:false)"
            [ngStyle]="{'cursor': (userService.user.role == 'owner' && d['role'] == 'mg_admin') ? 'not-allowed': 'pointer'}">
            <span class="badge user-inactive-status" (click)="emitAutomationStatus(d)" (click)="changeStatus(d)"
              [ngStyle]="{'pointer-events': (userService.user.role == 'owner' && d['role'] == 'mg_admin') ? 'none': 'visible'}">{{this.admin_data.off}}</span>
          </td>

          <td *ngIf="
              key == 'is_locked' &&
              d[key] == 0 &&
              (userService.user.role == 'superadmin' || userService.user.role == 'owner' ||  userService.user.role == 'mg_admin'  ||  userService.user.role == 'app_admin' || userService.user.role == 'partner_admin')
            ">
            <span class="badge user-inactive-status no-cursor no-cursor">{{this.admin_data.unlocked}}</span>
          </td>
          <td *ngIf="key == 'api_secret'">
            <div class="d-inline-flex">
              <input class="form-control code-value" #clip value="{{ d[key] }}" disabled />
              <button class="btn btn-purple" type="button" ngxClipboard [cbContent]="clip.value"
                (cbOnSuccess)="copySuccess(i, 'api_secret')" [class.btn-success]="api_secret_copied[i]">
                {{this.admin_data.copy}}
              </button>
              <!-- <button class="code-copy-btn" type="button"[cbContent]="clip.value"
              (cbOnSuccess)="copySuccess(i, 'api_secret')" >{{this.admin_data.copy}}</button> -->
            </div>
          </td>
          <td *ngIf="key == 'is_suspend' && d[key] == 1">
            <span class="badge user-inactive-status" (click)="changeStatus(d)">{{this.admin_data.usersVariable5}}</span>
          </td>
          <td *ngIf="
              key == 'is_locked' &&
              d[key] == 1  &&
              (userService.user.role == 'superadmin' || userService.user.role == 'owner' ||  userService.user.role == 'mg_admin'  ||  userService.user.role == 'app_admin' || userService.user.role == 'partner_admin')
            ">
            <span class="badge user-inactive-status" (click)="
              (userService.user.role == 'superadmin' || userService.user.role == 'owner' || userService.user.role == 'mg_admin' || userService.user.role == 'partner_admin') ? changeLock(d) : ''
              ">{{this.admin_data.locked}}</span>
          </td>
          <td *ngIf="key == 'anonymous_access' || key == 'jit_provisioning'">
            <ng-container *ngIf="d[key] == '1'"><span class="yesDot"></span>{{this.admin_data.yes}}</ng-container>
            <ng-container *ngIf="d[key] == '0'"><span class="noDot"></span>{{this.admin_data.no}}</ng-container>
          </td>

          <td *ngIf="key == 'is_publish'">
            <!-- <select [disabled]="(d['guide_count'] == '0' && d[key] == 0) || d['setting'] == null"
              class="form-control w-70 d-inline-block" (change)="emitPublishStatus(d, $event.target.value)">
              <option [selected]="d[key] == 1" [value]="1">{{this.admin_data.publish}}</option>
              <option [selected]="d[key] == 0" [value]="0">{{this.admin_data.unpublished}}</option>
            </select> -->

            <app-custom-select [dataList]="guideStatusArray" (updateDataList)="emitPublishStatus(d, $event)"
              [isDisabled]="(d['guide_count'] == '0' && d[key] == 0) || d['setting'] == null" [selected]="d[key]"
              componentName="default" dropdownPosition="inherit" [ngStyle]="{ 'position': 'relative' }"></app-custom-select>
          </td>
          <td *ngIf="
              !(
                key == 'app_action' ||
                key == 'role_action' ||
                key == 'creation_date' ||
                key == 'modification_date' ||
                key == 'segment_action' ||
                key == 'segment_list_action' ||
                key == 'user_action' ||
                key == 'export_guide_action' ||
                key == 'role' ||
                key == 'org_role_id' ||
                key == 'is_suspend' ||
                key == 'is_locked' ||
                key == 'isAutomationEnabled' ||
                key == 'secret_action' ||
                key == 'domain_action' ||
                key == 'details' ||
                key == 'api_connector_action' ||
                key == 'anonymous_access' ||
                key == 'jit_provisioning' ||
                key == 'api_secret' ||
                key == 'is_publish' ||
                key == 'task_action' ||
                key == 'sso_action'
              )
            ">
            {{ d[key] }}
          </td>
          <td *ngIf="key == 'role'">
            {{getUserRoleValue(d['role'])}}
            <!-- <select *ngIf="!((userService.user.role == 'owner') && d['role'] == 'mg_admin')"
              class="form-control form-control-sm"
              (change)="emitAssignRole($event.target.value, d)"
              [disabled]="isPiiDataHashed(d['settings']) || d[key] == 'superadmin'"
            >
              <option *ngFor="let data of guideRoleFilter"
                [selected]="d[key] == data['selectedValue']" 
                [value]="data['key']">
               {{[data['value']]}}
              </option>
            </select>

            <select
              *ngIf="(userService.user.role == 'owner') && d['role'] == 'mg_admin'"
              class="form-control form-control-sm"
              (change)="emitAssignRole($event.target.value, d)"
              [disabled]="(d['role'] == 'mg_admin' && userService.user.role == 'owner')"
            >
              <option
                [selected]="d[key] == 'mg_admin'" 
                value="mg_admin">
                 MG Admin
              </option>
            </select> -->

            <select
              *ngIf="(userService.user.role == 'app_admin') && (d['role'] == 'mg_admin' || d['role'] == 'owner' ||d['role'] == 'superadmin')"
              class="form-control form-control-sm" (change)="emitAssignRole($event.target.value, d)"
              [disabled]="(d['role'] == 'app_admin' && (d['role'] == 'mg_admin'|| d['role'] == 'owner' || d['role'] == 'superadmin'))">
              <option [selected]="d[key] == d['role']" [value]="d['role']">
                <span *ngIf="d['role'] == 'mg_admin'">MG Admin</span>
                <span *ngIf="d['role'] == 'owner'">Admin</span>
                <span *ngIf="d['role'] == 'superadmin'">Super Admin</span>
                <span *ngIf="d['role'] == 'partner_admin'">Partner Admin</span>
              </option>
            </select>




          </td>
          <td *ngIf="key == 'org_role_id'">
            {{setSelectedRole(d['org_role_id'],allRoles)}}
            <!-- {{ role["title"] }} -->
            <!-- <select
              [disabled]="((d['role'] == 'mg_admin' && userService.user.role == 'owner') || d['role'] == 'owner')"
              class="form-control form-control-sm"
              (change)="emitOrgAssignRole($event.target.value, d)"
            >
            <ng-container *ngIf="setSelectedRole(d['org_role_id'],allRoles)">
              <option 
                *ngFor="let role of allRoles"
                [selected]="d[key]==role['role_id']"
                [value]="role['role_id']"
              >
                {{ role["title"] }}
              </option>
            </ng-container>
            <ng-container *ngIf="!setSelectedRole(d['org_role_id'],allRoles)">
              <option 
                *ngFor="let role of allRoles"
                [selected]="role['role_id']=='0'"
                [value]="role['role_id']"
              >
                {{ role["title"] }}
              </option>
            </ng-container>
            </select> -->
          </td>
          <td *ngIf="key == 'details'">
            <button class="btn btn-primary" (click)="emitDetails(d['data'])"
              [disabled]="!(disable.indexOf(d['action']) == -1)"> {{this.admin_data.detailButtonText}} </button>
          </td>
          <!-- [ngStyle]="{
            'cursor': (((key == 'user_action') && (userService.user.role != 'superadmin') && (d['role'] == 'superadmin')) || userService.user.role == 'app_admin')? 'not-allowed': (userService.user.role == 'owner' && d['role'] == 'mg_admin') ? 'not-allowed': 'pointer'
          }" -->
          <td class="actions_wrapper" *ngIf="
              key == 'app_action' ||
              (key == 'role_action' && userService.user.role != 'app_admin')||
              key == 'segment_action' ||
              key == 'user_action' ||
              key == 'export_guide_action' ||
              key == 'secret_action' ||
              key == 'domain_action' ||
              key == 'api_connector_action' ||
              key == 'task_action' ||
              key == 'sso_action'
            " [ngStyle]="{
              'cursor': (((key == 'user_action') && (userService.user.role != 'superadmin') && (d['role'] == 'superadmin')) )? 'not-allowed': ( (key == 'user_action') && userService.user.role == 'owner' && d['role'] == 'mg_admin') ? 'not-allowed': 'pointer'
            }">
            <!-- <div class="action_icon mx-1" *ngIf="key == 'app_action'" data-toggle="tooltip"
                        data-placement="bottom" title="Tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                            <rect width="29" height="29" rx="14.5" fill="#EBF7F5" />
                            <mask id="path-2-inside-1" fill="white">
                                <path
                                    d="M6 8.38867C6 7.83639 6.44772 7.38867 7 7.38867H11V22.3887H7C6.44772 22.3887 6 21.941 6 21.3887V8.38867Z" />
                            </mask>
                            <path
                                d="M6 8.38867C6 7.83639 6.44772 7.38867 7 7.38867H11V22.3887H7C6.44772 22.3887 6 21.941 6 21.3887V8.38867Z"
                                stroke="#38B6A0" stroke-width="2.4" mask="url(#path-2-inside-1)" />
                            <mask id="path-3-inside-2" fill="white">
                                <path
                                    d="M10 7.38867H14C14.5523 7.38867 15 7.83639 15 8.38867V21.3887C15 21.941 14.5523 22.3887 14 22.3887H10V7.38867Z" />
                            </mask>
                            <path
                                d="M10 7.38867H14C14.5523 7.38867 15 7.83639 15 8.38867V21.3887C15 21.941 14.5523 22.3887 14 22.3887H10V7.38867Z"
                                stroke="#38B6A0" stroke-width="2.4" mask="url(#path-3-inside-2)" />
                            <mask id="path-4-inside-3" fill="white">
                                <path
                                    d="M14.1777 8.26953L18.0466 7.25369C18.5808 7.11344 19.1275 7.43277 19.2678 7.96695L22.5692 20.5407C22.7095 21.0749 22.3902 21.6217 21.856 21.7619L17.9871 22.7778L14.1777 8.26953Z" />
                            </mask>
                            <path
                                d="M14.1777 8.26953L18.0466 7.25369C18.5808 7.11344 19.1275 7.43277 19.2678 7.96695L22.5692 20.5407C22.7095 21.0749 22.3902 21.6217 21.856 21.7619L17.9871 22.7778L14.1777 8.26953Z"
                                stroke="#38B6A0" stroke-width="2.4" mask="url(#path-4-inside-3)" />
                            <rect x="8" y="11.3887" width="1" height="7" rx="0.5" fill="#38B6A0" />
                            <rect x="12" y="11.3887" width="1" height="7" rx="0.5" fill="#38B6A0" />
                            <rect x="17.3843" y="11.8267" width="0.5" height="6.5" rx="0.25"
                                transform="rotate(-15.6325 17.3843 11.8267)" fill="#38B6A0" stroke="#38B6A0"
                                stroke-width="0.5" />
                        </svg>
                    </div> -->
            
            <div class="action_icon mx-1"
            *ngIf="key == 'user_action' && (source == 'topLevelInternalUsers') && (d['role'] == 'partner_admin')"
            (click)="emitViewOrganization(d)"
                 data-toggle="tooltip" data-placement="bottom" title="{{this.admin_data.viewOrganizations}}">
            <svg-icon src="../../../assets/svgs/visibility-icon.svg"></svg-icon>
            </div>
            <div class="action_icon mx-1"
              *ngIf="key == 'user_action' && (source == 'topLevelInternalUsers') && (d['role'] != 'partner_admin') && (d['role'] != 'superadmin')"
              (click)="emitOrgWithMapping(d)" [ngStyle]="{
                  'pointer-events':(d['role'] == 'mg_admin')? 'visible': 'none'
                }" data-toggle="tooltip" data-placement="bottom" title="{{this.admin_data.usersVariable33}}">
              <svg-icon src="../../../assets/svgs/emit_assign.svg"></svg-icon>
            </div>
            <div class="action_icon mx-1" *ngIf="key == 'app_action' && !(userService.user.role == 'app_admin')"
              (click)="emitRole(d)" data-toggle="tooltip" data-placement="bottom"
              title="{{this.admin_data.manageRoleVariable1}}">
              <svg-icon src="../../../assets/svgs/emit_assign.svg"></svg-icon>
            </div>
            <div class="action_icon mx-1" *ngIf="key == 'secret_action'" (click)="emitRole(d)" data-toggle="tooltip"
              data-placement="bottom" title="{{this.admin_data.applicationsAssignTitle}}">

              <svg-icon *ngIf="!(userService.user.role == 'app_admin')"
                src="../../../assets/svgs/emit_assign.svg"></svg-icon>
              <!-- <span class="primaryFont-color fontWeight-700 font13">{{this.admin_data.applicationsAssignTitle}}</span> -->
            </div>
            <!-- <span *ngIf="key == 'secret_action'" class="divider"></span> -->
            <div class="action_icon mx-1" *ngIf="key == 'segment_action'" (click)="emitRole(d)" data-toggle="tooltip"
              data-placement="bottom" title="{{this.admin_data.assignUserAttributes}}">
              <svg-icon *ngIf="!(userService.user.role == 'app_admin')"
                src="../../../assets/svgs/emit_assign.svg"></svg-icon>
            </div>
            <div class="action_icon mx-1" *ngIf="
                (key == 'app_action' ||
                  key == 'role_action' ||
                  (key == 'user_action' && source!= 'topLevelInternalUsers') ||
                  key == 'api_connector_action' ||
                  (key == 'domain_action' && userService.enableSSO && userService.user.role == 'superadmin') ||
                  key == 'sso_action' ||
                  key == 'segment_action' || key == 'secret_action')
                " data-toggle="tooltip" data-placement="bottom" title="{{this.admin_data.tableTitle2}}"
              (click)="emitEdit(d)" [ngStyle]="{
                'pointer-events':((key == 'user_action') && (userService.user.role != 'superadmin') && (d['role'] == 'superadmin'))? 'none': (userService.user.role == 'owner' && d['role'] == 'mg_admin') ? 'none': ((userService.user.role == 'app_admin' && key == 'user_action') && (d['role'] == 'mg_admin' || d['role'] == 'owner' || d['role'] == 'superadmin')) ?  'none' : 'visible'
              }">
              <svg-icon src="../../../assets/svgs/emit_edit.svg"></svg-icon>
            </div>
            <div class="action_icon mx-1" *ngIf="
               (key == 'app_action' &&  (userService.user.role != 'app_admin' && userService.user.role != 'owner'))||
                key == 'role_action' ||
                key == 'segment_action' ||
                key == 'segment_list_action' ||
                key == 'user_action' ||
                key == 'secret_action' ||
                key == 'domain_action' ||
                key == 'sso_action' ||
                key == 'api_connector_action'
              " (click)="emitDelete(d)" [ngStyle]="{
                'pointer-events':((key == 'user_action') && (userService.user.role != 'superadmin') && (d['role'] == 'superadmin'))? 'none': (userService.user.role === 'owner' && d['role'] === 'mg_admin') ? 'none':((userService.user.role == 'app_admin' && key == 'user_action') && (d['role'] == 'mg_admin' || d['role'] == 'owner' || d['role'] == 'superadmin')) ?  'none' : 'visible',
                'cursor': (userService.user.role === 'owner'|| userService.user.role === 'app_admin') && key == 'app_action' ? 'not-allowed':'pointer'
              }" data-toggle="tooltip" data-placement="bottom" title="{{this.admin_data.deleteButtonText}}">
              <!-- <span class="primaryFont-color fontWeight-700 font13">{{this.admin_data.delete}}</span> -->
              <svg-icon src="../../../assets/svgs/emit_delete.svg"></svg-icon>
            </div>
            <!-- *ngIf="!(userService.user.role == 'app_admin')" -->
            <div class="action_icon mx-1" *ngIf="key == 'user_action'" data-toggle="tooltip"
              (click)="toggleShow($event.target)" [ngStyle]="{
                'pointer-events':((key == 'user_action') && (userService.user.role != 'superadmin') && (d['role'] == 'superadmin'))? 'none':(userService.user.role == 'owner' && d['role'] == 'mg_admin') ? 'none': ((userService.user.role == 'app_admin' && key == 'user_action') && (d['role'] == 'mg_admin' || d['role'] == 'owner' || d['role'] == 'superadmin')) ?  'none' : 'visible'
              }" data-placement="bottom" title="{{this.admin_data.headerVariable3}}" [routerLink]="[
                '/organization',
                d['organization_id'],
                'change_user_password',
                d['user_id']
              ]" [queryParams]="{user: d['user_email']}">
              <svg-icon src="../../../assets/svgs/change_password.svg"></svg-icon>
            </div>
            <div class="action_icon mx-1" *ngIf="key == 'user_action'" data-toggle="tooltip" data-placement="bottom"
              (click)="clearSession.emit([d['user_id'],i])" [ngStyle]="{
                'pointer-events':((key == 'user_action') && (userService.user.role != 'superadmin') && (d['role'] == 'superadmin'))? 'none':(userService.user.role == 'owner' && d['role'] == 'mg_admin') ? 'none': ((userService.user.role == 'app_admin' && key == 'user_action') && (d['role'] == 'mg_admin' || d['role'] == 'owner' || d['role'] == 'superadmin')) ?  'none' : 'visible'
              }" title="{{this.admin_data.tableTitle5}}">
              <svg-icon src="../../../assets/svgs/clear_session.svg"></svg-icon>
            </div>
            <ng-container *ngIf="key == 'task_action'">
              <div class="action_icon mx-1" *ngIf="key == 'task_action'" (click)="emitVisibility(d)"
                data-toggle="tooltip" data-placement="bottom" title="{{this.admin_data.visibility}}"
                [ngStyle]="{'pointer-events':(userService.user.role == 'app_admin' && d['application_id'] != userService.user.app_access)  ?  'none' : 'visible'}">
                <svg-icon class="visibility-icon" src="../../../assets/svgs/visibility-icon.svg"></svg-icon>
              </div>
              <div class="action_icon mx-1" *ngIf="d['is_publish'] == '0'" (click)="emitEdit(d)" data-toggle="tooltip"
                data-placement="bottom" title="{{this.admin_data.tableTitle2}}"
                [ngStyle]="{'pointer-events':(userService.user.role == 'app_admin' && d['application_id'] != userService.user.app_access)  ?  'none' : 'visible'}">
                <svg-icon src="../../../assets/svgs/emit_edit.svg"></svg-icon>
              </div>
              <div class="action_icon mx-1" [routerLink]="[
                  '/organization',
                  userService.organization_id,
                  'tasks_list',
                  d['application_id'],
                  d['id'],
                  'manage_content'
                ]" [queryParams]="{
                  list: d['title'],
                  application: d['application_title']
                }" data-toggle="tooltip" data-placement="bottom" title="{{this.admin_data.tableTitle3}}"
                (click)="toggleShow($event.target)"
                [ngStyle]="{'pointer-events':(userService.user.role == 'app_admin' && d['application_id'] != userService.user.app_access)  ?  'none' : 'visible'}">
                <svg-icon src="../../../assets/svgs/manage_content.svg"></svg-icon>
              </div>
              <div class="action_icon mx-1" *ngIf="d['is_publish'] == '0'" (click)="emitDelete(d)" data-toggle="tooltip"
                data-placement="bottom" title="{{this.admin_data.deleteButtonText}}"
                [ngStyle]="{'pointer-events':(userService.user.role == 'app_admin' && d['application_id'] != userService.user.app_access)  ?  'none' : 'visible'}">
                <svg-icon src="../../../assets/svgs/emit_delete.svg"></svg-icon>
              </div>
            </ng-container>

            <ng-container *ngIf="key=='export_guide_action'">
              <a [href]="d['export_guide_action']" [ngStyle]="{
                      'pointer-events':d['export_download_disabled'] ? 'none':'visible',
                      'color':d['export_download_disabled'] ? '#cfd0dd':''
                    }">


                <!-- <svg-icon *ngIf="d['progress']=='100'" class="mr-2 abcde"
              [src]="d['export_download_disabled'] ? '../../assets/svgs/disable_download_icon.svg' :'../../assets/svgs/download_icon.svg'">
              </svg-icon> -->
                <h6 *ngIf="d['progress']=='100'"
                  class="d-inline-block bottom-0 m-0 font13 fontWeight-700 primaryFont-color">
                  {{this.admin_data.download}}
                </h6>
              </a>
            </ng-container>
          </td>
          <td [class.modifi_dates_wrapper]="key == 'modification_date'"
            *ngIf="key == 'creation_date' || key == 'modification_date'">
            {{ d[key] * 1000 | date: "EEE, MMM d, y" }}
          </td>
        </ng-container>
      </tr>
    </tbody>
    <tbody *ngIf="tableData.length == 0">
      <tr class="text-center">
        <td [attr.colspan]="titles.length">{{this.admin_data.noDataFound}}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <ng-container *ngFor="let title of titles">
          <th *ngIf="!(title == 'Mark All')">{{ title }}</th>
          <th *ngIf="title == 'Mark All'">
            <label class="custom-check">
              <input type="checkbox" (click)="markAll($event.target.checked)" />
              &nbsp; &nbsp; {{this.admin_data.selectAll}}
              <span class="checkmark"></span>
            </label>
          </th>
        </ng-container>
      </tr>
    </tfoot>
  </table>
</div>