import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

@Injectable({
  providedIn: 'root'
})
export class ToastModalService {

  showToast = false;
  toastType = "";
  toastParam = "";
  constructor(private toastr: ToastrService) { }

  showToastMessage(toastType, message?) {
    console.log(toastType)
    switch (toastType) {
      case "add_application":
        this.toastr.success( adminLabels.applicationCreatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "update_application":
        this.toastr.success( adminLabels.applicationUpdatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "update_automation_status":
          this.toastr.success( adminLabels.automationStatusUpdatedText , adminLabels.successfullyText, {
            timeOut: 2000
          });
          this.showToast = false;
        break;

      case "delete_application":
        this.toastr.error( adminLabels.applicationDeletedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "add_role":
        this.toastr.success( adminLabels.roleCreatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "update_role":
        this.toastr.success( adminLabels.roleUpdatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "delete_role":
        this.toastr.error( adminLabels.roleDeletedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "generate_secret_key":
        this.toastr.success( adminLabels.credentialsCreatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "delete_secret_key":
        this.toastr.error( adminLabels.credentialsDeletedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "assign_app_to_keys":
        this.toastr.success( adminLabels.applicationAssignedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;


      case "add_segment":
            this.toastr.success(adminLabels.userAttributeCreatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "update_segment":
            this.toastr.success(adminLabels.userAttributeUpdatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "delete_segment":
            this.toastr.error(adminLabels.userAttributeDeletedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "add_segment_value":
            this.toastr.success(adminLabels.userAttributeValueCreatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "delete_segment_value":
            this.toastr.error(adminLabels.userAttributeValueDeletedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "add_user":
        this.toastr.success( adminLabels.userCreatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "update_user":
        this.toastr.success( adminLabels.userUpdatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "delete_user":
        this.toastr.error( adminLabels.userDeletedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

        case "assign_role":
        this.toastr.success( adminLabels.roleAssignedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

        case "assign_role_app":
        this.toastr.success( adminLabels.roleAssignedToApplicationText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

        case "assign_org_role":
        this.toastr.success( adminLabels.organisationRoleAssignedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "add_domain":
        this.toastr.success( adminLabels.domainCreatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "delete_domain":
        this.toastr.error( adminLabels.domainDeletedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

        case "update_sso_domain_mapping":
          this.toastr.success( adminLabels.updatedDomainSsoMapping , adminLabels.successfullyText, {
            timeOut: 2000
          });
        this.showToast = false;
        break;   

      case "add_connector":
        this.toastr.success( adminLabels.apiConnectorAddedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "delete_connector":
        this.toastr.error( adminLabels.apiConnectorDeletedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "add_task_list":
        this.toastr.success( adminLabels.taskListCreatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "update_task_list":
        this.toastr.success( adminLabels.taskListUpdatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "save_visibility_settings":
        this.toastr.success( adminLabels.visibilitySettingsUpdatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "delete_task_list":
        this.toastr.error( adminLabels.taskListDeletedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "admin_settings":
        this.toastr.success( adminLabels.adminSettingsUpdatedText , adminLabels.successfullyText);
        this.showToast = false;
        break;

      case "import_user":
        this.toastr.success( adminLabels.importedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "user_unlock":
        this.toastr.success( adminLabels.userUnlockedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "user_status":
        this.toastr.success( adminLabels.userStatusChangedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "clear_session":
        this.toastr.success( adminLabels.sessionClearedforUserText + this.toastParam , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;
        // adminLabels.sessionClearedforUserText

      case "change_password":
        this.toastr.success( adminLabels.passwordChangedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "update_org":
        this.toastr.success( adminLabels.organisationUpdatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "change_active_status":
        this.toastr.success( adminLabels.organisationActiveStatusChangedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;
        
      case "change_verify_status":
        this.toastr.success( adminLabels.organisationVerifiedStatusChangedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;
          
      case "add_org":
        this.toastr.success( adminLabels.organisationCreatedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "delete_org":
        this.toastr.error( adminLabels.organisationDeletedText , adminLabels.successfullyText, {
          timeOut: 2000
        });
        this.showToast = false;
        break;

      case "add_sso":
        this.toastr.success( adminLabels.ssoCreatedText , adminLabels.successfullyText, {
          timeOut: 2000
      });
      this.showToast=false;
      break;

      case "delete_sso":
        this.toastr.error( adminLabels.ssoDeletedText , adminLabels.successfullyText, {
          timeOut: 2000
      });
      this.showToast=false;
      break;

      case "trigger_orgjson":
        this.toastr.success(adminLabels.changesReflectedText , adminLabels.instantUpdateText, {
          timeOut: 2000
      });
      this.showToast=false;
      break;

      case "clear_orgsession":
        this.toastr.success(adminLabels.sessionClearedForOrganisationText , adminLabels.sessionClearedText, {
          timeOut: 2000
      });
      this.showToast=false;
      break;

      case "sso_config":
        this.toastr.success( adminLabels.ssoConfigurationUpdatedText , adminLabels.successfullyText, {
          timeOut: 2000
      });

      case "export_data":
        this.toastr.success(message, adminLabels.successText, {
          timeOut: 2000
        });
      this.showToast=false;
      break;

      case "invalid_image_type": 
        this.toastr.error(message, adminLabels.invalidImageText, {
          timeOut: 2000
        });
      this.showToast=false;
      break;

      case "max_guide_selected": 
        this.toastr.info(message, adminLabels.maxGuideSelected, {
          timeOut: 2000
        });
      this.showToast=false;
      break;

      case "copied_guides": 
        this.toastr.success(message, adminLabels.copied, {
          timeOut: 2000
        });
      this.showToast=false;
      break;

      case "pasted_guides": 
      this.toastr.success(message, adminLabels.pasted, {
        timeOut: 2000
      });
    this.showToast=false;
    break;

    case "export_guides": 
      this.toastr.success(message, adminLabels.exportMessage, {
        timeOut: 2000
      });
    this.showToast=false;
    break;

    case "import_guides": 
      this.toastr.success(message, adminLabels.importMessage, {
        timeOut: 2000
      });
    this.showToast=false;
    break;

    case "guide_sequence_updated": 
      this.toastr.success(message, adminLabels.guideSequenceUpdated, {
        timeOut: 2000
      });
    this.showToast=false;
    break;

    case "media_file_creation_tooltip_guide_disable": 
      this.toastr.info(message, adminLabels.mediaFileCreationForTooltipGuidesToastInfo, {
        timeOut: 2000
      });
    this.showToast=false;
    break;
    
    case "guide_published":
            {this.toastr.success(message, adminLabels.guidePublishedSuccessfully, {
            timeOut: 2000
        });
        this.showToast = false;
        break;}
        
    case "guide_unpublished":
            this.toastr.success(message, adminLabels.guideUnpublishedSuccessfully, {
            timeOut: 2000
        });
        this.showToast = false;
            break;

        case "export_inventory":
            this.toastr.success(message, adminLabels.exportInventoryToastMessage, {
            timeOut: 3000
            });
        this.showToast = false;
            break;

        case "global_feature_changed":
            this.toastr.success(message, adminLabels.featureStatusChanged, {
                timeOut: 3000
            });
            this.showToast = false;
            break;
        case "visibility_settings_updated":
           { this.toastr.success(message, adminLabels.visibilitySettingsUpdated, {
                timeOut: 3000
            });
            this.showToast = false;
            break;}
        case "video_generation_triggered":
            { this.toastr.success(message, adminLabels.videoGenerationStarted, {
                    timeOut: 3000
                });
                this.showToast = false;
                break;}
    default:
        this.showToast = false;
        break;

    }
  }

}
